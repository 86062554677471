import React from 'react';
import { Row, Col, Radio, Space, Button, Input } from 'antd';
import constants from '../../../Constants/constants';
import { closeModal } from '../../../GlobalFunctions/GlobalFunctions';
import { fetchDownloadApi } from '../../../Services/Api';

const PreviewContent = ({allQuestions, surveyName, surveyTypeTitle, setShowPreviewModal, surveyId, }) => {
	const downloadPreviewContent = () => {
    fetchDownloadApi(`/download-question-pdf/${surveyId}`, 'POST').then((res) => {
        if (res) {
            const blob = new Blob([res], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${surveyName}.pdf`; // Set the filename here
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url); // Clean up the URL object
        }
    });
}

	const getQuestionTypeWiseFormat = (qType, surveyType, eachObject)=>{
		let htmlData;
		switch (surveyType) {
			case 'Courses':
				htmlData = qType === '1' ?
					<Radio.Group disabled={true}>
						<Space direction="vertical">
						{constants.CoursesOptions.map((option)=>(
							<Radio value={option.option_id} className='text-dark fw-normal'>{option.option_name}</Radio>
						))}
						</Space>
					</Radio.Group>
					:
					<><Input className='formattedInputField' /><Input className='formattedInputField' /></>
				break;
			case 'Instructor':
				htmlData = qType === '5' ?
					<span></span>
					:
					<div><Input className='formattedInputField' /><Input className='formattedInputField' /></div>
				break;
			default:
				htmlData = qType === '1' ?
					<Radio.Group disabled={true}>
						<Space direction="vertical">
							{eachObject?.options.map((option, index) => {
								return <Radio key={option} value={index} className='text-dark fw-normal'>{option?.option_text}</Radio>
							})}
						</Space>
					</Radio.Group>
					:
					<><Input className='formattedInputField' /><Input className='formattedInputField' /></>
				break;
		}
		return <div className="mb-2">{htmlData}</div>
	}
	
	return (
		<div className="p-2">
			<div className="text-center text-xl mb-2">{surveyName}</div>
			<ol className='h-[65vh] overflow-y-auto'>
				{allQuestions.map((question) => (
					<>
						<li key={question?.id} className='fw-medium mb-2 list-decimal'>{question?.question_text} {question?.is_mandatory === "1" ? <span className='text-danger text-2xl'>*</span> : ""}</li>
						<div>{getQuestionTypeWiseFormat(question?.question_type, surveyTypeTitle, question)}</div>
						<br />
					</>
				))}
			</ol>
			<div className="flex justify-end">
				<Button
					type="primary"
					className="me-2"
					onClick={() => closeModal(setShowPreviewModal)} danger>
					{constants.Cancel}
				</Button>
				<Button
					type='primary'
					onClick={downloadPreviewContent}>
					{constants.Download}
				</Button>
			</div>
		</div>
	)
}
export default PreviewContent
