import React, { useState,useEffect } from 'react';
import { Select, Button, Form, Row, Col, Table, Checkbox, Space, Modal } from 'antd';
import constants from '../../../Constants/constants';
import { showTotalItems, getNextAndPreviousButtons, handleRowSelect } from '../../../GlobalFunctions/GlobalFunctions';
import dayjs from 'dayjs';

const ModalContent = ({ form, closeModal, selectedRecord, semesterData, setShowSent, showSent, sendSurveyTabColumns, setShowSendModal, surveyTypes, sendModalData, clearKeys, setIsPartTime, isPartTime, SendData,setSelectedSemesterId,getSurveyRecepientsData,setSelectedYearGroups,rowKeysModal, setRowKeysModal,rowDataModal, setRowDataModal,setRefresh, refresh,setSendModalData,selectedSurveyTypeSendTo,setCheckYearSelected,loading }) => {
	const [surveyType, setSurveyType] = useState('');
	const [hideYearProgram,setHideYearProgram] = useState(false);
	const [visibleMakeActiveModal,setVisibleMakeActiveModal] = useState(false);
	let selectedSurvey=null;
useEffect(()=>{
	if(semesterData?.length===1){
		getSurveyRecepientsData(0,false,false);
	}else if(semesterData?.length>1){
		setSelectedSemesterId(semesterData[0]?.id)
	}
	setRefresh(!refresh);
	let selectedSurveyData = surveyTypes.filter((each) => (each?.id === selectedRecord?.survey_type ? each.content : ''))
	setSurveyType(selectedSurveyData[0]?.content);
	selectedSurvey=selectedSurveyData[0]?.content;
	if(selectedSurvey==="Mentors"){
		getYearTypes();
	}else if(selectedSurvey!=="Mentors"||selectedSurvey!=="Instructor"||selectedSurvey!=="Courses"){
		if(selectedSurveyTypeSendTo?.length>1){
			form.setFieldsValue({ NotificationTo: ['1', '2', '3', '4', '6'] })
			setSelectedYearGroups([1, 2, 3, 4, 6])
			setCheckYearSelected([1, 2, 3, 4, 6])
		}else{
			if(selectedSurveyTypeSendTo?.includes("0")){
				form.setFieldsValue({ NotificationTo: ['1', '2', '3', '4'] })
				setSelectedYearGroups([1, 2, 3, 4])
				setCheckYearSelected([1, 2, 3, 4])
			}else{
				setHideYearProgram(true);
			}
		}
	}
	console.log(selectedRecord)
}, []);
	const getYearTypes = ()=>{
		form.setFieldsValue({ NotificationTo: ['1', '2', '3', '4'] })
		setSelectedYearGroups([1, 2, 3, 4])
		setCheckYearSelected([1, 2, 3, 4])
	}
	const clearYearTypes = () => {
		form.setFieldsValue({ NotificationTo: [] })
		setSelectedYearGroups([])
		setCheckYearSelected([])
	}
	const disablePastDates = (current) => {
		// Can not select days before today and today
		return current && current < dayjs().startOf('day');
	};
	const onSelectChange = (newSelectedRowKeys = [], selectedRowData = [], record = null, index = null,selectAll = false) => {
		handleRowSelect(
			newSelectedRowKeys, //KEYS FROM ON SELECT CHANGE
			selectedRowData, //RECORDS FROM ON SELECT CHANGE
			record, //CURRENT SELECTED RECORD
			index, //CURRENT SELECTED RECORD INDEX
			{ rowKeys: rowKeysModal, rowData: rowDataModal }, //GET STATES FOR KEYS AND RECORDS
			{ setRowKeys: setRowKeysModal, setRowData: setRowDataModal }, //SET STATES FOR KEYS AND RECORDS
			'multi', //SELECTION TYPE
			selectAll,
		)

  };
	const rowSelection = {
		fixed: 'left',
		columnWidth: '5%',
		selectedRowKeys: rowKeysModal,
		onSelect: (record, selected, selectedRows) =>onSelectChange(
			selectedRows?.map((item) => item.id), //RETRIEVE ONLY IDs
			selectedRows, //ARRAY OF RECORDS WHICH ARE SELECTED
			record, //CURRENT SELECTED RECORD
			sendModalData?.findIndex((item) => item.id === record.id) //INDEX OF THE CURRENTLY SELECTED RECORD FROM TABLE DATA
		),
		onSelectAll: (selected, selectedRows, changeRows) => {
			let data = selected ? sendModalData : []
			onSelectChange(
				data?.map((item) => item.id),
				data,
				null,
				null,
				true
			)
		},
	};
	const handleSelectedSemesterId=(value)=>{
		setSelectedSemesterId(value);
	}
	const handleChangeShowSent=()=>{
		setShowSent(!showSent);
	}
	const handleChangePartTime=(e)=>{
		setIsPartTime(e.target.checked)
	}
	const onChangeYears=(values)=>{ // Convert to integers
			form.setFieldsValue({NotificationTo:values});
			setSelectedYearGroups(values);
			setCheckYearSelected(values);
  }
	const buttonSubmitCall = () =>{
		SendData();
		clearKeys();
		setRowDataModal([]);
		setRowKeysModal([]);
		form.setFieldsValue({NotificationTo:null})
	}
	return (
		<Form 
			form={form}
			id="myForm"
			layout='vertical'
		>
			<>
				<Row gutter={[8, 8]}>
					{((surveyType!=="Courses"&&surveyType!=="Instructor")&&!hideYearProgram)?
						<Col span={24}>
							<Form.Item
								// hidden={mentorTypeNotification}
								name="NotificationTo"
								className='w-full !mb-2'
								initialValue={null}
							>
								<Select
									mode="multiple"
									maxTagCount='responsive'
									placeholder='Year'
									onChange={(value)=>onChangeYears(value)}
									dropdownRender={(menu) => (
										<div className='w-full'>
											<Button
												type="text"
												className='w-50 border border-1 !important'
												onClick={getYearTypes}
											>
												Select All
											</Button>
											<Button
												type="text"
												className='w-50 border border-1 !important'
												onClick={clearYearTypes}
											>
												Deselect All
											</Button>
											{menu}
										</div>
									)}
								>
									{(surveyType==="Mentors"?
									constants.TypesForMentors:
									selectedSurveyTypeSendTo?.length>1?constants.TypesForCustom:selectedSurveyTypeSendTo?.includes("0")?
									constants.TypesForMentors:constants.TypesForCustom).map((option) => (
										<Select.Option
											key={option?.value}
											value={option?.value}
										>
											{option?.label}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						:
						null
					}
					{
						semesterData?.length>1?
							<Col span={12}>
								<Form.Item label={constants.Semester} className='!mb-2'>
									<Select placeholder='Type' maxTagCount='responsive' onChange={handleSelectedSemesterId}defaultValue={semesterData[0]?.id}
									>
										{semesterData?.map((sem) => (
											<Select.Option 
												key={sem.semester} 
												value={sem.id}
											>
												{sem.semester}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							:
							<div></div>
					}
				</Row>
				<div className='flex justify-between items-center mb-2'>
					{(surveyType === 'Instructor' || surveyType === 'Mentors')?"":
						<Space>
						<strong>{constants.PartTime} :</strong>
						<Form.Item className='!mb-0'>
							<Checkbox onChange={handleChangePartTime} checked={isPartTime}></Checkbox>
						</Form.Item>
					</Space>
					}
					<Button
						className='btn-info'
						onClick={() => {
							handleChangeShowSent();
						}}>
						{showSent ? constants.ShowNotSent : constants.ShowSent}
					</Button>
				</div>
				<Table
					className='records-table data_table'
					bordered
					size="small"
					columns={sendSurveyTabColumns}
					dataSource={sendModalData}
					pagination={{
						pageSize: constants.TablePageSize,
						showTotal: (total, range) => showTotalItems(total, range, sendModalData),
						itemRender: getNextAndPreviousButtons,
					}}
					scroll={{
						y: '40vh',
					}}
					rowKey={(record) => record?.id}
					onRow={(record, rowIndex) => {
						if(!showSent){
							return {
								onClick: () => onSelectChange(null, null, record, rowIndex),
							}
						}
					}}
					rowSelection={!showSent?rowSelection:null}
				>
				</Table>
				<div className="flex justify-end mt-3">
					<Button 
						type="primary"
						className='mb-3'
						onClick={()=>{
							closeModal(setShowSendModal);
							clearKeys();
							setRowDataModal([]);
							setRowKeysModal([]);
							setSendModalData([]);
							setCheckYearSelected([]);
							form.setFieldsValue({NotificationTo:null})
							}}
							danger>
							{constants.Cancel}
						</Button>
						{!showSent&&
							selectedRecord?.is_active&&selectedRecord?.is_active==="1"?
							<Button
								htmlType='submit' 
								type="primary" 
								className='ms-2 text-sm mb-3'
								disabled={showSent || sendModalData?.length===0 || loading}
								onClick={()=>{
									buttonSubmitCall();
								}}>
								{constants.Send}
							</Button>:
							<Button
								//htmlType='submit' 
								type="primary" 
								className='ms-2 text-sm mb-3'
								disabled={showSent || sendModalData?.length===0|| loading}
								onClick={()=>{
									setVisibleMakeActiveModal(true);
								}}>
								{constants.Send}
							</Button>
						}
				</div>
				{visibleMakeActiveModal&&
					<Modal
						title={constants.Confirm}
						open={visibleMakeActiveModal}
						onCancel={()=>setVisibleMakeActiveModal(false)}
						footer={false}
					>
						<>
							<div className='text-sm mb-2 font-bold'>{constants.MakeSurveyActiveText}</div>
							<div className="mt-3 flex justify-end">
								<Button type='primary' danger onClick={()=>setVisibleMakeActiveModal(false)}>Cancel</Button>
								<Button
									type='primary'
									onClick={()=>{buttonSubmitCall();setVisibleMakeActiveModal(false)}}
									htmlType='submit'
									form='myForm'
									className='ms-2'
									disabled={loading}
								>
									{constants.Send}
								</Button>
							</div>
						</>
					</Modal>}
			</>
		</Form>
	)
}
export default ModalContent
