import React, { useEffect } from 'react';
import { Input, Select, Button, Form, Space, Table } from 'antd';
import constants from '../../Constants/constants';
import { getNextAndPreviousButtons, handleRowSelect, performSearch, showTotalItems } from '../../GlobalFunctions/GlobalFunctions';

const ModalContent = ({ type, form, showAssignModal, handleAssignSectionFormChange, showReAssignModal, handleReAssignSectionFormChange, showViewModal, handleViewSectionFormChange, allMentorsExceptSelectedOne, tableColumns, studentsAssigned, saveMentorDetails, selectedMentors, id, selectedRecord, closeModal, setShowChangeStatusModal, changeStudentStatus, newSearchText, setNewSearchText, setFilteredStudentsAssigned, filteredStudentsAssigned, setSelectedRowKeys, modifiedYearTypes,rowKeysModal,setRowKeysModal,setIsMentorchanged,setmentorId,newMentorId,rowDataModal,setRowDataModal,loading }) => {
	const onSearch = (e) => {
		const search = e;
		setNewSearchText(search);
		const searchFieldNames = [
			"name",
			"year_in_program_text",
			"student_type_value"
		];
		let searchedValue = performSearch(search, studentsAssigned, searchFieldNames);
		setFilteredStudentsAssigned(searchedValue);
	}
	const onSelectChange = (newSelectedRowKeys = [], selectedRowData = [], record = null, index = null,selectAll = false) => {
		handleRowSelect(
			newSelectedRowKeys, //KEYS FROM ON SELECT CHANGE
			selectedRowData, //RECORDS FROM ON SELECT CHANGE
			record, //CURRENT SELECTED RECORD
			index, //CURRENT SELECTED RECORD INDEX
			{ rowKeys: rowKeysModal, rowData: rowDataModal }, //GET STATES FOR KEYS AND RECORDS
			{ setRowKeys: setRowKeysModal, setRowData: setRowDataModal }, //SET STATES FOR KEYS AND RECORDS
			'multi', //SELECTION TYPE
			selectAll,
		)
  };
	useEffect(() => {
		setmentorId(null);
		setRowDataModal([]);
		setRowKeysModal([]);
		setNewSearchText("")
	}, [])
	const rowSelection = {
		fixed: 'left',
    selectedRowKeys: rowKeysModal,
    onSelect: (record, selected, selectedRows) =>
			onSelectChange(
			selectedRows?.map((item) => item.id), //RETRIEVE ONLY IDs
			selectedRows, //ARRAY OF RECORDS WHICH ARE SELECTED
			record, //CURRENT SELECTED RECORD
			filteredStudentsAssigned?.findIndex((item) => item.id === record.id) //INDEX OF THE CURRENTLY SELECTED RECORD FROM TABLE DATA
		),
		onSelectAll: (selected, selectedRows, changeRows) => {
			let data = selected ? filteredStudentsAssigned : []
			onSelectChange(
				data?.map((item) => item.id),
				data,
				null,
				null,
				true
			)
		},
  };
	const getNewMentor = (value) => {
		setIsMentorchanged(true);
		setmentorId(value);
	}
	return (
		<div>
			<Form form={form}
				onValuesChange={showAssignModal ? handleAssignSectionFormChange : showReAssignModal ? handleReAssignSectionFormChange : showViewModal ? handleViewSectionFormChange : ''}
			>
				<Form.Item name='mentor_type' label='Select Mentor' hidden={type !== 2} className='!mb-2.5'>
					<Select 
						maxTagCount='responsive' 
						onChange={getNewMentor}
						placeholder='Mentor'
						className="!w-[18.5rem]"
						allowClear
					>
						{
						allMentorsExceptSelectedOne && allMentorsExceptSelectedOne.map((each) => (
							<Select.Option key={each} value={each?.id}>{each?.student_name}</Select.Option>
						))
						}
					</Select>
				</Form.Item>
				
				<div className="flex justify-between">
					<Space>
						<Form.Item name='student_type' hidden={type === 4} className="w-36 !mb-2.5">
							<Select 
								maxTagCount='responsive'
								placeholder='Type'
								allowClear
								>
								{constants.StudentTypeList.map((record) =>
									<Select.Option value={record.value} key={record.label}>{record.label}</Select.Option>
								)}
							</Select>
						</Form.Item>
						<Form.Item name='student_year' hidden={type === 4} className="w-36 !mb-2.5">
							<Select 
								maxTagCount='responsive'
								placeholder='Year'
								allowClear
								disabled={form.getFieldValue('student_type') === 2}
							>
								{modifiedYearTypes.map((option) => (
									<Select.Option key={option} value={Number(option?.year_in_program)}>
										{option?.label}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						</Space>
					<Form.Item hidden={type === 4} className="!mb-2.5">
						<Input 
							placeholder={constants.Search} 
							onChange={(e)=>{onSearch(e.target.value)}} 
							value={newSearchText} 
							className="!w-48"
						></Input>
						</Form.Item>
				</div>
						<Form.Item hidden={type === 4}>
							<Table
								bordered
								className='records-table data_table mentorTable'
								columns={tableColumns}
								dataSource={filteredStudentsAssigned?filteredStudentsAssigned:[]}
								scroll={{ y: '50vh' }}
								rowKey={(record) => record.id}
								onRow={(record, rowIndex) => {
									if(type === 1 || type === 2 ){
										return {
											onClick: () => onSelectChange(null, null, record, rowIndex),
										}
									}
								}}
								rowSelection={type === 1 || type === 2 ?rowSelection:null}
								size='small'
								pagination={{
									pageSize: constants.TablePageSize,
									showTotal: (total, range) => showTotalItems(total, range, filteredStudentsAssigned?filteredStudentsAssigned:[]),
								itemRender: getNextAndPreviousButtons,
								}}
							>
							</Table>
						</Form.Item>
						
						{/* <Form.Item hidden={type !== 1 || type !== 2 || type !== 4}> */}
						<div className="flex justify-end">
							{
								type === 1 || type === 2 ?
									<Button
									  type='primary'
										className=" text-capitalize btn-info mb-3"
										onClick={() => type === 1 ? saveMentorDetails(1) : saveMentorDetails(2)}
										disabled={(type!==1?(rowKeysModal?.length === 0||!newMentorId):rowKeysModal?.length === 0)||loading}
									>{type === 1 ? constants.AssignBtnLabel : constants.ReAssignBtnLabel}
									</Button>
                  :
									<div></div>
							}
						</div>
									
						<Form.Item hidden={type !== 4}>
							<div>
								{
									id === '2' && selectedRecord?.student_count === null ?
										<div className="font-bold text-sm">{constants.MentorWithNoStudentMessage}</div>
										:
										id === '2' && selectedRecord?.student_count !== null ?
											<div className="font-bold text-sm">{constants.MentorWithStudentMessage}</div>
											:
											<div className="font-bold text-sm">{constants.RejectedRequestStatusChangeMessage}</div>
								}
								<div className="flex justify-end mb-3 mt-2">
									<Button type="primary" onClick={() => {closeModal(setShowChangeStatusModal);setSelectedRowKeys([]);setRowKeysModal([]);setRowDataModal([]);setmentorId(null);setNewSearchText("")}} danger>{constants.Cancel}</Button>
									{
										(id === '2' && selectedRecord?.student_count === null) || (id === '3') ?
											<Button type='primary' disabled={loading} className='ms-2 text-light' onClick={()=>changeStudentStatus(id)}>{constants.Yes}</Button>
											:
											<div></div>
									}
								</div>
							</div>
						</Form.Item>
				
			</Form>
		</div>
	);
}

export default ModalContent
