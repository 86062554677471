import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Table, Select, Button, Form, Checkbox, Input, Badge, message, Space } from 'antd';
import BreadcrumbHeader from '../../Component/BreadcrumbHeader';
import { getModal, showModal, closeModal, CustomSortIcon, getNextAndPreviousButtons, showTotalItems, myTrim, handleRowSelect, sortTwoNumbers, performSearch } from '../../../GlobalFunctions/GlobalFunctions';
import constants from '../../../Constants/constants';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { fetchStaffApi, fetchApi } from '../../../Services/Api';
import ModalContent from '../ManageSurveys/ModalContent';
import AddAndEditSurveys from './AddAndEditSurveys';

function getRoute() {
  let splitRoute = window.location?.pathname?.split('/')
  return `/${splitRoute[splitRoute?.length -1]}`
}

const ManageSurveys = () => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [showAddModal, setShowAddModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showSendModal, setShowSendModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [rowKeys, setRowKeys] = useState([]);
	const [visibilityValue, setVisibilityValue] = useState(false);
	const [selectedRecord, setSelectedRecord] = useState({});
	const [surveys, setSurveys] = useState([]);
	const [surveyTypes, setSurveyTypes] = useState([]);
	const [semesterData, setSemesterData] = useState(0);
	const [showSent, setShowSent] = useState(false);
	const [continueSave, setContinueSave] = useState();
	const [alertType, setAlertType] = useState();
	const [surveyHidden,setSurveyHidden] = useState(false);
	const [isHiddenSurvey,setHiddenSurvey] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [filteredSurveys, setFilteredSurveys] = useState([]);
	const [actionType,setActionType] = useState();
	const [sendModalData,setSendModalData] = useState([]);
	const [isPartTime,setIsPartTime] = useState(false);
	const [allYears,setAllYears] = useState([]);
	const [rowData, setRowData] = useState([]);
	const [selectedSurveyType,setSelectedSurveyType]=useState(null);
	const [selectedSemesterId,setSelectedSemesterId]=useState(null);
	const [selectedYearGroups,setSelectedYearGroups]=useState([]);
	const [rowKeysModal, setRowKeysModal] = useState([]);
	const [rowDataModal, setRowDataModal] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [selectedSurveyTypeSendTo, setSelectedSurveyTypeSendTo] = useState(null);
	const [checkYearSelected,setCheckYearSelected] = useState([]);
	const [newAddedRecord,setNewAddedRecord] = useState(null);
	const [loading,setLoading] = useState(false);

	const onSelectChange = (newSelectedRowKeys = [], selectedRowData = [], record = null, index = null) => {
		handleRowSelect(
			newSelectedRowKeys, //KEYS FROM ON SELECT CHANGE
			selectedRowData, //RECORDS FROM ON SELECT CHANGE
			record, //CURRENT SELECTED RECORD
			index, //CURRENT SELECTED RECORD INDEX
			{ rowKeys: rowKeys, rowData: rowData }, //GET STATES FOR KEYS AND RECORDS
			{ setRowKeys: setRowKeys, setRowData: setRowData }, //SET STATES FOR KEYS AND RECORDS
		)
		let selectedData= record?record:[...selectedRowData]
		setSelectedRecord(selectedData); // Update selectedRecord if a row is selected
  };
	const rowSelection = {
		fixed: 'left',
		columnWidth: '2px',
		selectedRowKeys: rowKeys,
		onSelect: (record, selected, selectedRows) => onSelectChange( 
			// selectedRows?.map((item) => item.id), //RETRIEVE ONLY IDs
			// selectedRows, //ARRAY OF RECORDS WHICH ARE SELECTED
			null,
			null,
			record, //CURRENT SELECTED RECORD
			filteredSurveys?.findIndex((item) => item.id === record.id) //INDEX OF THE CURRENTLY SELECTED RECORD FROM TABLE DATA
		),
		hideSelectAll:true,
	};
	const breadcrumbItems = [
		{
			label: constants.ManageSurveys,
			active: true
		}
	];
	useEffect(() => {
		getAllSurveyTypes();
		getSemesterData();
	}, []);
	useEffect(()=>{
		if (!showAddModal || !showEditModal || !showDeleteModal){
			setAlertType();
			setSurveyHidden(false)
		}
	}, [showAddModal, showEditModal, showDeleteModal])
	useEffect(()=>{
		if(searchText){
			onSearch(searchText)
		}
	}, [surveys]);
	useEffect(() => {
		if (alertType === 0 && surveyHidden){
			message.success(constants.DataSaved);
			clearKeys();
			getAllSurveys(selectedSurveyType,isHiddenSurvey);
		} else if (alertType === 0 && !surveyHidden){
			message.success(constants.SurveyDeletedSuccessfully);
			getAllSurveys(selectedSurveyType,isHiddenSurvey);
		}
	}, [alertType, surveyHidden])
	useEffect(() => {
		let showSentBtnValue = showSent?1:0;
		let partOrFullTime = isPartTime?2:1;
		let semesterId=null;
		if(semesterData.length>1){
			let selectedValue = selectedSemesterId?semesterData.some(eachSem=>eachSem.id===selectedSemesterId&&eachSem.semester_is_ended==="0")?0:2:0;
			semesterId=selectedValue;
		}else{
			semesterId=0;
		}
		getSurveyRecepientsData(semesterId,partOrFullTime,showSentBtnValue);
	}, [selectedSemesterId,isPartTime,showSent,selectedYearGroups,refresh]);
	const getSemesterData = () => {
		fetchApi('/semester-data', 'get').then((res) => {
			if (res?.code === "200"&&res?.data?.length>0){
				setSemesterData(generateSelectDropdown(res.data));
				if(res?.data?.length===1){
					setSelectedSemesterId(res.data[0]?.id);
				}
			}else{
				setSemesterData([]);
			}
		});
	}
	const generateSelectDropdown = (data) => {
		let filteredData = data?.map((item) => {
			const semesterObj = constants.StudentSemList?.find((sem) => sem.key === item.semester_type)
			return {
				...item,
				semester: item?.semester_is_ended === '0' ? `Current(${semesterObj?.value})` : semesterObj?.value
			}
		}).sort((a, b) => { return a.semester_is_ended - b.semester_is_ended })
		return filteredData
	}
	const getAllSurveyTypes = () => {
		fetchStaffApi(`/get-survey-types`, 'GET').then((res) => {
			if (res?.data) {
				setSurveyTypes(res?.data.map(({ content, id, survey_send_to}) => ({content, id, survey_send_to})));
				getAllSurveys(null,isHiddenSurvey);
			} else {
				setSurveyTypes([]);
			}
		}).catch(() => {
			message.error(constants.ErrorMessage);
		});
	}
	const getAllSurveys = (type,isHiddenSurvey) => {
		let payload={
			is_hidden:isHiddenSurvey?1:0,
		}
		if(type||selectedSurveyType){
			payload.type=type;
		}
		fetchStaffApi(`/get-all-surveys`, 'POST', payload).then((res) => {
			if (res?.data?.length>0){
				const surveysWithIndex = res?.data.map((item, index) => ({ ...item, index }));
				setSurveys(surveysWithIndex);
				setFilteredSurveys(surveysWithIndex);
			} else {
				setSurveys([]);
				setFilteredSurveys([]);
			}
		}).catch(() => {
			message.error(constants.ErrorMessage);
		});
	}
	useEffect(() => {
		if (continueSave === 2) {
			redirectToQuestionsPage("fromAddSurvey");
		}
	}, [continueSave]);

	const getSurveyRecepientsData = (semesterId,partOrFullTime,showSentBtnValue)=>{
		let mentorTypePayload = null;
		let instructorTypePayload = null;
		let coursesTypePayload = null;
		let customSurveyTypePayload = null;
		mentorTypePayload ={
			survey_id:parseInt(selectedRecord?.id),
			semester:parseInt(semesterId),
			sent_status: showSentBtnValue,
			student_type:1,
			year_in_program:selectedYearGroups.map(Number),
			survey_type: Number(selectedRecord?.survey_type),
		}
		if(checkYearSelected.length>0){
			mentorTypePayload.year_in_program=selectedYearGroups.map(Number);
		}
		instructorTypePayload={
			course_id: parseInt(selectedRecord?.course_id),
			survey_id: parseInt(selectedRecord?.id),
			sent_status: showSentBtnValue,
			survey_type: parseInt(selectedRecord?.survey_type),
			semester:parseInt(semesterId),
		}
		coursesTypePayload = {
			course_id: selectedRecord?.course_id,
			survey_id: selectedRecord?.id,
			sent_status: showSentBtnValue,
			part_time: isPartTime,
			semester:parseInt(semesterId),
			survey_type: parseInt(selectedRecord?.survey_type),
		}
		customSurveyTypePayload={
			survey_id: selectedRecord?.id,
			sent_status: showSent,
			part_time: isPartTime,
			survey_type: parseInt(selectedRecord?.survey_type),
			semester: parseInt(semesterId),
		}
		if(checkYearSelected.length>0){
			if(selectedYearGroups?.length>1){
				if(selectedYearGroups?.includes(6)||selectedYearGroups?.includes("6")){
					customSurveyTypePayload.survey_send_to=selectedSurveyTypeSendTo;
				}else{
					customSurveyTypePayload.survey_send_to=["0"];
				}
			}else{
				if(selectedYearGroups?.includes(6)||selectedYearGroups?.includes("6")){
					customSurveyTypePayload.survey_send_to=["1"];
				}else{
					customSurveyTypePayload.survey_send_to=["0"];
				}
			}
		}else{
			customSurveyTypePayload.survey_send_to=selectedSurveyTypeSendTo;
		}
		if(checkYearSelected.length>0){
			customSurveyTypePayload.year_in_program=selectedYearGroups.map(Number);
		}
		let surveyTypeName = selectedRecord?.survey_type_name;
		let payload = surveyTypeTitle[0]?.content === 'Mentors' ? mentorTypePayload: 
		surveyTypeTitle[0]?.content === 'Instructor' ? instructorTypePayload: 
		surveyTypeTitle[0]?.content === 'Courses' ? coursesTypePayload : 
			customSurveyTypePayload;
		let checkCustom = surveyTypeTitle[0]?.content !== 'Courses'&&surveyTypeTitle[0]?.content !== 'Instructor'&&surveyTypeTitle[0]?.content !== 'Mentors';
		if(surveyTypeName){
			fetchStaffApi(`/get-survey-recipients`, 'POST', payload).then((res) => {
				if(res.data){
					let recipientsWithIndex = [];
					if(checkCustom){
						if(res?.data?.recipientData?.length>0){	
							recipientsWithIndex = res?.data?.recipientData.map((item, index) => ({
								ids:item.id,
								...item,
								id:index,
							}));
						}else{recipientsWithIndex = []}
					}else{
						if(res.data.length>0){
							recipientsWithIndex = res?.data?.map((item, index) => ({
								...item,
								id:index,
							}));
						}else{recipientsWithIndex = []}
					}
					setSendModalData(recipientsWithIndex);
					const yearTypes = [...new Set(recipientsWithIndex&&recipientsWithIndex.map((record) => record.year_in_program))];
					setAllYears(yearTypes);
					if(res?.data?.recipientData?.length===0){
						setLoading(true);
					}else{
						setLoading(false);
					}
				}else{
					setLoading(false);
					setSendModalData([]);
					// setAllYears([]);
				}
			}).catch(() => {
				setLoading(false);
				message.error(constants.ErrorMessage);
			});
		}
	}
	let sendSurveyTabColumnsCustom = [
		{
			title: constants.Name,
			key: '1',
			dataIndex: 'name',
			width:'25%'
		},
		{
			title:'Year in Program',
			key: '2',
			width: '25%',
			render: (record) => {
				let yearType = constants.Yeartype[record?.year_in_program] || ''
				return yearType;
			}
		},
		{
			title: 'Type',
			key: '3',
			width: '25%',
			render:(record)=>{
				return constants.StudentTypeList.find(item=>item.value===record?.student_type)?.label || '';
			}
		}
	]
	let sendSurveyTabColumnsOthers = [
		{
			title: constants.Name,
			key: '1',
			dataIndex: 'student_name',
			width:'25%'
		},
		{
			title:'Year in Program',
			key: '2',
			width: '25%',
			render: (record) => {
				let yearType = constants.Yeartype[record?.year_in_program] || ''
				return yearType;
			}
		},
		{
			title: 'Student Type',
			key: '3',
			width: '25%',
			render:(record)=>{
				return constants.StudentTypeList.find(item=>item.value===record?.student_type)?.label || '';
			}
		}
	]
	const sendSurveyTabColumns = [
		{
			title: constants.Name,
			key: '1',
			dataIndex: 'faculty',
			width: '25%',
			align:'left',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => { return a.faculty.localeCompare(b.faculty) },
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
		{
			title: 'Position',
			key: '2',
			align:'left',
			width: '25%',
			render:(record)=>{
				let newObj = Object.assign({}, ...(constants.facultyType.map(item => ({ [item.key]: item.option }))));
				const toBeRenderedRole = newObj[Number(record?.faculty_roles)] || '';
				return toBeRenderedRole;
			}
		}
	]
	const surveytabColumns = [
		{
			title: constants.Name,
			key: '1',
			width: '25%',
			showSorterTooltip: { placement: 'bottom' },
			render: (record) => {
				const surveyName = record?.survey_name || '';
				const highlightedSurveyName = surveyName.toLowerCase().includes(searchText.toLowerCase()) ? surveyName.replace(new RegExp(`(${searchText})`, 'gi'), '<span class="highlight">$1</span>') : surveyName;
				return (
					<div className="d-flex justify-content-between">
						<span dangerouslySetInnerHTML={{ __html: highlightedSurveyName }}></span>
						<span>{
							record?.is_active === "1" ? <Badge.Ribbon text={constants.Active} color="#00A65A"  className='top-0 !important me-3 text-xs'></Badge.Ribbon> : <span></span>
						}</span>
					</div>
				);
			},
			sorter: (a, b) => {
				return a?.survey_name?.localeCompare(b?.survey_name)
			},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
		{
			title: constants.Type,
			key: '2',
			width: '15%',
			dataIndex: 'survey_type',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => {
				const contentA = surveyTypes?.find((type) => type?.id === a?.survey_type)?.content || '';
				const contentB = surveyTypes?.find((type) => type?.id === b?.survey_type)?.content || '';
				return contentA?.localeCompare(contentB);
			},
			render: (text, record, index) => {
				const surveyType = surveyTypes.filter((each)=>(each?.id === record?.survey_type ? each.content : ''))
				const highlightedSurveyType = surveyType[0]?.content.toLowerCase().includes(searchText.toLowerCase()) ? surveyType[0]?.content.replace(new RegExp(`(${searchText})`, 'gi'), '<span class="highlight">$1</span>') : surveyType[0]?.content;
				return <span dangerouslySetInnerHTML={{ __html: highlightedSurveyType }}></span>
			},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
		{
			title: constants.SurveySent,
			key: '3',
			width:'15%',
			showSorterTooltip: { placement: 'bottom' },
			sorter: (a, b) => sortTwoNumbers(a,b,"sent_date",true),
			render: (record) => {
				return(record?.sent_date?<>
					<span className='text-sm font-bold'>Yes</span><span className='ms-2'>
						({dayjs(record?.sent_date).format(constants.DateFormat)})
					</span></>
					:<span className='text-sm font-semibold'>No</span>)
			},
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
		{
			title: 'Total',
			key: '4',
			width: '10%',
			showSorterTooltip: { placement: 'bottom' },
			render: (_, record) => (
				<span className="numbers-alignment">{record?.total ? record?.total : '-'}</span>
			),
			sorter: (a, b) => sortTwoNumbers(a,b,"total"),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
		{
			title: 'Pending',
			key: '5',
			width: '10%',
			showSorterTooltip: { placement: 'bottom' },
			render: (_, record) => (
				<span className="numbers-alignment">{record?.pending ? record?.pending : '-'}</span>
			),
			sorter: (a, b) => sortTwoNumbers(a,b,"pending"),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
		{
			title: 'Resent',
			key: '6',
			width: '10%',
			showSorterTooltip: { placement: 'bottom' },
			render: (_, record) => (
				<span className="numbers-alignment">{record?.resend ? record?.resend : '-'}</span>
			),
			sorter: (a, b) => sortTwoNumbers(a,b,"resend"),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
		{
			title: 'Answered',
			key: '7',
			width: '10%',
			showSorterTooltip: { placement: 'bottom' },
			render: (_, record) => (
				<span className="numbers-alignment">{record?.complete ? record?.complete : '-'}</span>
			),
			sorter: (a, b) => sortTwoNumbers(a,b,"complete"),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
	]
	const onSearch = (e) => {
		const search = e;
		clearKeys('search');
		setSearchText(search);
		const searchFieldNames = [
			"survey_name",
			'survey_type_name',
		];
		let searchedValue = performSearch(search, surveys, searchFieldNames);
		setFilteredSurveys(searchedValue);
	}
	const validateToDate = (_, value) => {
		const startDate = form.getFieldValue('start_date');
		if (startDate && value && dayjs(value).isBefore(startDate)) {
			const errorPromise = Promise.reject(new Error("Start date must be greater than end date"));
			return errorPromise;
		}

		Promise.resolve().then(() => {

		});
		return Promise.resolve();
	};
	const hideSelectedSurvey = () => {
		setLoading(true);
		let payload={hide:!isHiddenSurvey}
		fetchStaffApi(`/hide-survey/${selectedRecord?.id}`,'POST',payload).then((res) => {
			if (res?.code === '200') {
				closeModal(setShowDeleteModal);
				setAlertType(0);
				setSurveyHidden(true);
				clearKeys("success");
				setLoading(false);
			} else {
				closeModal(setShowDeleteModal);
				setAlertType(1)
				clearKeys();
				setLoading(false);
			}
		}).catch(() => {
			setLoading(false);
			message.error(constants.ErrorMessage);
		});
	}

	const deleteModalContent = (status) => {
		let message;
		let allTheButtons = (
			<div className="flex justify-end mt-2">
				<Button danger type='primary' className="mb-3" onClick={() => { closeModal(setShowDeleteModal); clearKeys();}} >{constants.Cancel}</Button>
				{
					<Button disabled={loading} type='primary' className='ms-2 mb-3' onClick={hideSelectedSurvey}>{isHiddenSurvey?constants.UnHide:constants.Hide}</Button>
				}
			</div>
		);
		if(isHiddenSurvey){
				message = <div className='font-bold text-sm'>{constants.SurveyUnideConfirm}</div>
		}else{
			if (status === 1) {
				message = <div className='font-bold text-sm'>{constants.DeleteConfirmationMessage}</div>
			} else if (status === 2) {
				message = <div className='font-bold text-sm'>{constants.SurveyWithQuestionMessage}</div>
			}else {
				message = <div className='font-bold text-sm'>{constants.SurveyInUseAndHideMessage}</div>
			}
		}
		message = (
			<div>
				{message}
				{allTheButtons}
			</div>
		);
		return message;
	}
	const redirectToQuestionsPage = (type=null) => {
		let mainObject = {};
		let selectedOrAddedRecord = type ==="fromAddSurvey"?newAddedRecord:selectedRecord;
		const filteredObject = surveyTypes.filter((each) => (each?.id === selectedOrAddedRecord?.survey_type ? each.content : ''))
		const survey = filteredObject[0]?.content;
		if(type==="fromAddSurvey"){
			mainObject = { record: newAddedRecord, surveytype: survey }
		}else{
			mainObject = { record: selectedRecord, surveytype: survey }
		}
		navigate(`/questions`, { state: { surveyDetails: mainObject } });
	}
	const redirectToResultsPage = () => {
		let record = { 
			selectedRecord: selectedRecord,
			previousRoute: getRoute(),
		}
		navigate(`/survey-result`, { state: record });
	}
	const redirectToReportsPage = () => {
		let surveyObject = {};
		surveyObject = {
			selectedRecord: selectedRecord, 
		}
		//let surveyType = selectedRecord?.survey_type;
		navigate(`/surveys-report`, { state: surveyObject });
	}
	//get onChange value of start date field
	const getStartDate = (date, dateString)=>{
		let today = dayjs().format(constants.DateFormat);
		if (dateString === today){
			setVisibilityValue(true);
		}else{
			setVisibilityValue(false);
		}
	}
	const getToBeEditedValues = ()=>{
		setActionType(2)
		showModal(setShowEditModal);
	}
	let surveyTypeTitle = [];
	surveyTypeTitle = surveyTypes.filter((each) => (each?.id === selectedRecord?.survey_type ? each.content : ''))
	const buttonArray = [
		{
			key:1,
			class:'text-sm btn-info',
			disabled: rowKeys.length !== 0 ||isHiddenSurvey,
			title: constants.Add,
			onClick: () => {setActionType(1); showModal(setShowAddModal)},
		},
		{
			key: 2,
			class: 'text-sm btn-info',
			disabled: rowKeys.length === 0||isHiddenSurvey,
			title: constants.Edit,
			onClick: getToBeEditedValues,
		},
		{
			key: 3,
			class: 'text-sm btn-info',
			disabled: rowKeys.length === 0,
			title: isHiddenSurvey?constants.UnHide:constants.Hide,
			onClick: ()=>showModal(setShowDeleteModal),
		},
		{
			key: 4,
			class: 'text-sm btn-info',
			disabled: rowKeys.length === 0 /* || selectedRecord?.is_active === "0" */||(selectedRecord?.is_active === "1"&& selectedRecord?.delete_check===1)||isHiddenSurvey,
			title: constants.Send,
			onClick: () => { 
				if(selectedRecord?.is_active === "0"&&selectedRecord?.delete_check===1){
					message.error(<span>This survey do not have any question(s), Click <Button type='link' className='!px-0' onClick={()=>{redirectToQuestionsPage()}}>here</Button> to add.</span>)
				}else{
					setActionType(4);
					let record =surveyTypes.find(item=>item?.id===selectedRecord?.survey_type)
					if(record)setSelectedSurveyTypeSendTo(record?.survey_send_to)
					showModal(setShowSendModal);
				}
			},
		},
		{
			key: 5,
			class: 'text-sm btn-info',
			disabled: rowKeys.length === 0,
			title: constants.Questions,
			onClick:redirectToQuestionsPage,
		},
		{
			key: 6,
			class: 'text-sm btn-info',
			disabled: rowKeys.length === 0 || selectedRecord?.delete_check===1,
			title: constants.Result,
			onClick: redirectToResultsPage,
		},
		{
			key: 7,
			class: 'text-sm btn-info',
			disabled: rowKeys.length === 0,
			title: constants.Report,
			onClick:redirectToReportsPage,
		},
	]
	const getFilteredSurvey = (value,option)=>{
		setSelectedSurveyTypeSendTo(option?.option?.survey_send_to)
		setSelectedSurveyType(value);
		clearKeys();
		getAllSurveys(value,isHiddenSurvey);
	}
	const onChangeHiddenSurvey=(e)=>{
		setHiddenSurvey(e.target.checked);
		clearKeys();
		getAllSurveys(selectedSurveyType,e.target.checked);
	}
	const clearKeys = (type) => {
		if(type!=="search"){
			setRowKeys([]);
			setRowData([]);
		}
		setRowDataModal([]);
		setRowKeysModal([]);
		setSendModalData([]);
		setCheckYearSelected([]);
		if(type==="success"){
			getAllSurveys(selectedSurveyType,isHiddenSurvey);
		}
	}
	const SendData = ()=>{
		setLoading(true);
		let instructorTypePayload={
			semester_id:parseInt(selectedSemesterId),
			survey_id: parseInt(selectedRecord?.id),
			faculty_id:rowDataModal?rowDataModal?.map(obj => parseInt(obj.faculty_id)):[],
			survey_type: parseInt(selectedRecord?.survey_type),
		};
		let customTypePayload={
			survey_id : parseInt(selectedRecord?.id),
			survey_type : parseInt(selectedRecord?.survey_type),
			faculty_id : [],
			student_id : [],
			semester_id : parseInt(selectedSemesterId),
		}
		if(selectedRecord?.survey_type!=="1"||selectedRecord?.survey_type!=="2"||selectedRecord?.survey_type!=="3"){
			let studentIds=[];
			let facultyIds=[];
			for (let index = 0; index < rowDataModal.length; index++) {
				if(rowDataModal[index]?.type==="student"){
					studentIds.push(rowDataModal[index].ids)
				}else{
					facultyIds.push(rowDataModal[index].ids)
				}
			}
			customTypePayload.faculty_id=facultyIds;
			customTypePayload.student_id=studentIds;
		}

		let mentorTypePayload={
			semester_id:parseInt(selectedSemesterId),
			survey_id: parseInt(selectedRecord?.id),
			faculty_id : [],
			student_id : [],
			survey_type: parseInt(selectedRecord?.survey_type),
		};
		if(checkYearSelected){
			if(selectedYearGroups?.includes("6")){
				mentorTypePayload.faculty_id=rowDataModal?rowDataModal.map(obj => parseInt(obj.faculty_id)):[];
			}else{
				mentorTypePayload.student_id=rowDataModal?rowDataModal.map(obj => parseInt(obj.student_id)):[];
			}
		}
		let coursesTypePayload={
			semester_id:parseInt(selectedSemesterId),
			course_id: parseInt(selectedRecord?.course_id),
			survey_id: parseInt(selectedRecord?.id),
			student_id:rowDataModal?rowDataModal.map(obj => parseInt(obj.student_id)):[],
			survey_type: parseInt(selectedRecord?.survey_type),
		};
		let payload = selectedRecord?.survey_type==="2"?coursesTypePayload:
		selectedRecord?.survey_type==="1"?mentorTypePayload:
		selectedRecord?.survey_type==="3"?instructorTypePayload:customTypePayload
		fetchStaffApi(`/send-survey`, 'POST', payload).then((res) => {
			if (res?.code === '200') {
				closeModal(setShowSendModal);
				message.success(constants.SurveySentSuccessfully);
				clearKeys("success");//MainTableKeys
				setLoading(false);
			} else {
				closeModal(setShowSendModal);
				message.error(res?.message);
				clearKeys();//MainTableKeys
				setLoading(false);
			}
			setLoading(false);
		}).catch(() => {
			setLoading(false);
			message.error(constants.ErrorMessage);
		});
	}
	return (
		<div>
			<BreadcrumbHeader pageTitle={constants.ManageSurveys} breadcrumbItems={breadcrumbItems} />
			<Row>
				<Col span={24}>
					<Card className='container-border-custom position-relative'>
						<div className='flex justify-between flex-wrap gap-2 mb-2.5'>
							<div>
								<Space wrap>
									{
										buttonArray.map((btn) => {
											return <span key={btn.key}><Button className={btn.class} disabled={btn.disabled} onClick={btn.onClick}>{btn.title}</Button></span>
										})
									}
								</Space>
							</div>
							<Space wrap>
								<Select
									placeholder={constants.Type}
									className='w-36'
									maxTagCount='responsive'
									onChange={getFilteredSurvey}
									allowClear
									value={selectedSurveyType}
								>
									{surveyTypes.map((option) => (
										<Select.Option key={option.id} value={option?.id} option={option}>
											{option.content}
										</Select.Option>
									))}
								</Select>
								<Checkbox
									onChange={onChangeHiddenSurvey}
									value={isHiddenSurvey}
								>
									{constants.ShowHiddenSurveys}
								</Checkbox>
								<Input placeholder={constants.Search} className='w-48' onChange={(e)=>{onSearch(e.target.value)}} value={searchText}></Input>
							</Space>
						</div>
						<Table
							bordered
							onRow={(record, rowIndex) => {
								return {
									onClick: () => onSelectChange(null, null, record, rowIndex),
									onDoubleClick: () => getToBeEditedValues(),
								}
							}}
							rowSelection={rowSelection}
							className='records-table data_table'
							columns={surveytabColumns}
							dataSource={filteredSurveys}
							scroll={{ x: 768,y: 'calc(100vh - 300px)' }}
							size="small"
							rowKey={(record) => record?.id}
							pagination={{
								pageSize: constants.TablePageSize,
								showTotal: (total, range) => showTotalItems(total, range, filteredSurveys),
								itemRender: getNextAndPreviousButtons
							}}
						>
						</Table>
						{
							(showSendModal) && getModal(constants.SendSurvey,
								<ModalContent
									form={form}
									closeModal={closeModal}  
									selectedRecord={selectedRecord} 
									surveyTypes={surveyTypes} 
									semesterData={semesterData} 
									setShowSent={setShowSent}
									showSent={showSent}
									sendSurveyTabColumns={
										surveyTypeTitle[0]?.content === 'Instructor'?sendSurveyTabColumns:
										surveyTypeTitle[0]?.content !== 'Courses'&&surveyTypeTitle[0]?.content !== 'Instructor'&&surveyTypeTitle[0]?.content !== 'Mentors'
										?sendSurveyTabColumnsCustom:sendSurveyTabColumnsOthers }
									sendModalData={sendModalData}
									clearKeys={clearKeys}
									setIsPartTime={setIsPartTime}
									isPartTime={isPartTime}
									SendData={SendData}
									setShowSendModal={setShowSendModal}
									setSelectedSemesterId={setSelectedSemesterId}
									getSurveyRecepientsData={getSurveyRecepientsData}
									setSelectedYearGroups={setSelectedYearGroups}
									rowKeysModal={rowKeysModal}
									setRowKeysModal={setRowKeysModal}
									rowDataModal={rowDataModal}
									setRowDataModal={setRowDataModal}
									setRefresh={setRefresh}
									refresh={refresh}
									setSendModalData={setSendModalData}
									selectedSurveyTypeSendTo={selectedSurveyTypeSendTo}
									setCheckYearSelected={setCheckYearSelected}
									loading={loading}
									 />,
									(showSendModal), closeModal, 
								(setShowSendModal), () => clearKeys(),
								)
						}
						{showAddModal&&
							<AddAndEditSurveys
								isAddOrEdit={"add"}
								setShowAddModal={setShowAddModal}
								surveyTypes={surveyTypes}
								clearKeys={clearKeys}
								isHiddenSurvey={isHiddenSurvey}
								setShowEditModal={setShowEditModal}
								selectedRecord={selectedRecord}
								showAddModal={showAddModal}
								showEditModal={showEditModal}
								redirectToQuestionsPage={redirectToQuestionsPage}
								continueSave={continueSave}
								setContinueSave={setContinueSave}
								setNewAddedRecord={setNewAddedRecord}
							/>
						}
						{showEditModal&&
							<AddAndEditSurveys
								isAddOrEdit={"edit"}
								setShowAddModal={setShowAddModal}
								surveyTypes={surveyTypes}
								clearKeys={clearKeys}
								isHiddenSurvey={isHiddenSurvey}
								setShowEditModal={setShowEditModal}
								selectedRecord={selectedRecord}
								showAddModal={showAddModal}
								showEditModal={showEditModal}
								continueSave={continueSave}
								setContinueSave={setContinueSave}
								setNewAddedRecord={setNewAddedRecord}
							/>
						}
						{
							showDeleteModal && getModal(`${constants.DeleteSurvey} - ${selectedRecord?.survey_name}`, deleteModalContent(selectedRecord?.delete_check), showDeleteModal, closeModal, setShowDeleteModal, () => clearKeys())
						}
					</Card>
				</Col>
			</Row>
		</div>
	)
}
export default ManageSurveys
