import React, { useEffect, useState } from "react";
import constants from "../../Constants/constants";
import { Card, message, Spin, Space, Button } from "antd";
import { InboxOutlined, DownloadOutlined, FileTwoTone } from "@ant-design/icons";
import BreadcrumbHeader from "../Component/BreadcrumbHeader";
import FileUpload from "../Component/FileUpload";
import { beforeUploadCheckFileType } from "../../GlobalFunctions/GlobalFunctions";
import { useLocation } from "react-router-dom";
import { fetchStaffApi, fetchStaffDownloadApi, fetchStaffFormData } from "../../Services/Api";
import { COOKIE, getCookie } from "../../Services/Cookie";
import moment from "moment";

const AddSchedule = () => {
	const location = useLocation();
	const viewOnly = location.pathname === "/list-schedule" ? true:false;
	const pageTitle = viewOnly ? constants.AdvisingSchedule: `${constants.AddSchedule} - ${location.state?.faculty_name}`;
	const breadcrumbItems = viewOnly ? [{ label: constants.AdvisingSchedule, active: true }]:[
		{ label: constants.AssignAdvisors, href: '/assigning-advisors' },
		{ label: constants.AddSchedule, active: true }
	]
	const [fileList, setFileList] = useState([]);
	const [loading, setLoading] = useState(false);
	const isAdvisor = getCookie(COOKIE.IsAdvisor);

	useEffect(() => {
		getFacultyShedule()
	}, []);
	

	const onFileChange = (info) => {
		let checkBeforeUpload = beforeUploadCheckFileType(info?.file, false);
		if (checkBeforeUpload) {
			setFileList(info.fileList);
		}
	};

	const uploadRenderContent = <>
		<p className="ant-upload-drag-icon"> <InboxOutlined /></p>
		<p className="ant-upload-text">Click or drag file to this area to {constants.UploadSchedule}.</p>
		<p className="ant-upload-hint m-0">{constants.ValidFileType}</p>
		<p className="ant-upload-hint">{constants.ValidFileSize}</p>
		<Button className="text-capitalize btn-info mt-1">Browse</Button>
	</>

	const getFacultyShedule = () => {
		fetchStaffApi(`/view-schedule/${location?.state?.faculty_id}`,'GET',).then((res)=>{
			if(res?.code === "200"){
				const fileData = res?.data
				setFileList([{
					id: fileData?.id,
					name: <div className="flex justify-between cursor-pointer" title={fileData?.name}>
						<strong className="text-base ml-3">{fileData?.name}</strong>
						<Space className="mr-3">
							<strong className="text-base">{constants.UploadedSchedule}:</strong>
							<strong className="text-base">{fileData?.scheduleuploaded ? moment(fileData?.scheduleuploaded).format(constants.DateFormat):""}</strong>
							<DownloadOutlined className="text-lg"/>
						</Space>
					</div>,
					schedulefile:fileData?.schedulefile,
					type:fileData?.filetype,
					filename:fileData?.name,
					scheduleuploaded:fileData?.scheduleuploaded
				}]);
			}else{
				setFileList([]);
				console.error(res?.data?.message);
			}
		})
	}; 

	const handleUploadFile = async () => {
		setLoading(true);
		try {
			const formData = new FormData();
			fileList.forEach((file) => {
				if (file.originFileObj) {
					formData.append('file', file.originFileObj);
					formData.append('faculty_id',location?.state?.faculty_id)
				}
			});
			fetchStaffFormData(`/add-schedule`, 'POST', formData).then((res) => {
				if (res.code === "200") {
					setLoading(false);
					getFacultyShedule();
					setFileList([]);
					message.success(res?.message);
				}
				else {
					message.error(res?.message);
					setLoading(false);
				}
			});
		} catch (error) {
			message.error(error.message);
		}
	};

	const deleteUplodedFile = (file) => {
		fetchStaffApi(`/delete-schedule/${file?.id}`, 'delete').then((res) => {
			if (res?.code === "200") {
				getFacultyShedule();
				message.success(res?.message);
			} else {
				message.error(res?.message);
			}
		});
	};

	const downloadFile = (file) => {
		setLoading(true);
		let fileExtention = file.type;
		fetchStaffDownloadApi(`/download-schedule/${file.id}`, "get", null).then(
			(res) => {
				if (res) {
					setLoading(false);
					let url, a;
					url = window.URL.createObjectURL(
						new Blob([res], { type: "application" }),
						{ type: `data:attachment/${fileExtention}` }
					);
					a = document.createElement("a");
					a.download = file.filename;
					a.href = url;
					a.dispatchEvent(new MouseEvent("click"));
				} else {
					setLoading(false);
					message.error("Failed to download");
				}
			}
		);
	};

	const viewDocument = (doc,fileName, width, height,className) => {
		let fileExtention = fileName?.split('.').pop();
		if(fileExtention==="png"||fileExtention==="jpeg"||fileExtention==="jpg"||fileExtention==="pdf"){
			return <iframe width={width} height={height} className={className} src={doc + "#page=1&zoom=100"}></iframe>
		}
	};

	return (
		<>
			<BreadcrumbHeader pageTitle={pageTitle} breadcrumbItems={breadcrumbItems} />
			<div>
				<Card className="container-border-custom px-3 pb-3">
					<Spin tip={constants.PleaseWait} spinning={loading}>
						{
							!viewOnly &&
							<>
								<FileUpload
									renderContent={uploadRenderContent}
									listType='picture'
									name='file'
									className={/* viewOnly ? 'hide-file-upload' : */ ''}
									showUploadList={true}
									isMultiple={false}
									beforeUpload={beforeUploadCheckFileType}
									onChange={onFileChange}
									useDragger={true}
									fileCount={1}
									defaultFileList={fileList.length > 0 ? fileList : []}
									onRemoveFile={deleteUplodedFile}
									onPreviewFile={downloadFile}
								/>
								{
									(fileList?.[0]?.originFileObj) && (
										<Button className="text-capitalize btn-info mt-3" onClick={handleUploadFile}>{constants.Save}</Button>
									)
								}
								{fileList?.[0]?.schedulefile ?viewDocument(fileList?.[0]?.schedulefile,fileList?.[0]?.filename,"100%",400,"mt-2 img-sm"):null}
							</>
						}
						{
							(fileList.length === 0 && viewOnly) && (
								<strong className="text-base">{constants.NoSheduleToDisplay}</strong>
							)
						}
						{
							(fileList.length > 0 && viewOnly) && (
								<>
									<div className="h-12 bg-slate-200 rounded p-3 mb-2 flex justify-between items-center cursor-pointer" onClick={() => downloadFile(fileList?.[0])} >
										<Space>
											<FileTwoTone className="text-lg mr-2" />
											<strong className="text-base">{fileList?.[0]?.filename}</strong>
										</Space>
										<Space>
											<strong className="text-base">{constants.UploadedSchedule}:</strong>
											<strong className="text-base">{fileList?.[0]?.scheduleuploaded ? moment(fileList?.[0]?.scheduleuploaded).format(constants.DateFormat) : ""}</strong>
											<DownloadOutlined onClick={() => downloadFile(fileList?.[0])} className="text-lg"/>
										</Space>
									</div>
									{viewDocument(fileList?.[0]?.schedulefile,fileList?.[0]?.filename,"100%",700,"img-lg")}
								</>
							)
						}
					</Spin>
				</Card>
			</div>
		</>

	)
}
export default AddSchedule;