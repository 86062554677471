import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Radio, Space, Form, Button, Input,Alert, message, Card } from 'antd';
import BreadcrumbHeader from '../../Component/BreadcrumbHeader';
import constants from '../../../Constants/constants';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { EditOutlined, CloseOutlined,WarningFilled,RightOutlined, DragOutlined } from '@ant-design/icons';
import {fetchApi } from '../../../Services/Api';
import { getModal, showModal, closeModal, compareArrays} from '../../../GlobalFunctions/GlobalFunctions';
import { useLocation, useNavigate } from 'react-router-dom';
import QuestionContentModal from '../ManageSurveys/QuestionContentModal';
import PreviewContent from '../ManageSurveys/PreviewContent';

const Questions = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [allQuestions,setAllQuestions] = useState([]);
	const [expandedRows, setExpandedRows] = useState([]);
	const [questionTypeValue,setQuestionTypeValue] = useState();
	const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);
	const [showEditQuestionModal, setShowEditQuestionModal] = useState(false);
	const [showPreviewModal,setShowPreviewModal] = useState(false);
	const [showDeleteModal,setShowDeleteModal] = useState(false);
	const [actionType,setActionType] = useState();
	const [orderChanged,setOrderChanged] = useState(false);
	const [originalIds,setOriginalIds] = useState([]);
	const [newOrderIds, setNewOrderIds] = useState([]);
	const { surveyDetails } = location.state || {};

	const [editQuestionId, setEditQuestionId] = useState(null);
	const breadcrumbItems = [
		{
			label: constants.ManageSurveys,
			href: '/manage-surveys',
			active: false
		},
		{
			label: constants.Questions,
			active: true
		}
	];
	const columns = [
		{
			title: 'Name',
			key: 'question_text',
			className:"bg-slate-100",
			width:'85%',
			render: (text, record) => {
				return <div className="flex items-start" onClick={() => toggleRow(record?.objIndex)}>
					<span className='mr-3'><DragOutlined/></span>
					<span className='mr-[10px]'>
						<RightOutlined className={`transform transition-transform duration-300 text-xs ${record?.isOpen ? 'rotate-90' : 'rotate-0'}`}/>
					</span>
					<Space className='!items-start font-bold text-sm'>
						<span className='w-9'>{(record?.objIndex+1)}.</span> {record?.question_text}
					</Space>
				</div>
			}
		},
		{
			title: 'Action',
			key: 'age',
			align: 'right',
			className:"bg-slate-100",
			width:'25%',
			render: (record) => {
				return <span>
					<EditOutlined
						onClick={()=>{surveyDetails?.record?.is_active!=="1"&&displayEditModal(record?.id)}}
						className={surveyDetails?.record?.is_active==="1"?"!cursor-not-allowed me-3 fs-5":"cursor-pointer me-3 fs-5"}/>

					<CloseOutlined
						className={surveyDetails?.record?.is_active==="1"?"!cursor-not-allowed me-3 fs-5":"cursor-pointer fs-5 me-2"}
						onClick={()=>surveyDetails?.record?.is_active!=="1"&&displayDeleteModal(record?.id)}/>
				</span>
			}
		},
	];
	useEffect(()=>{
		getAllQuestions();
	}, [])
	const deleteQuestion = (questionId)=>{
		let payload = null;
		payload = { 
			id: questionId
		}
		fetchApi(`/delete-question`, 'POST', payload).then((res) => {
			if(res?.code === '200'){
				message.success(res?.message);
				getAllQuestions();
			}else{
				message.error(res?.message);
			}
			closeModal(setShowDeleteModal);
			sessionStorage.removeItem("qid");
		}).catch(() => {
			message.error(constants.ErrorMessage);
		});
	}
	const displayEditModal = (questionId)=>{
		setEditQuestionId(questionId);
		setActionType(2);
		setShowEditQuestionModal(true);
		sessionStorage.setItem('qId', questionId);
	}
	const displayDeleteModal = (questionId)=>{
		setActionType(3);
		setShowDeleteModal(true);
		sessionStorage.setItem('qId', questionId);
	}
	const getAllQuestions = () => {
		fetchApi(`/all-questions?survey_id=${surveyDetails?.record?.id}`, 'POST').then((res) => {
			if (res?.data) {
				const modifiedResponse = res?.data.map((item,rowindex) => ({
					...item,
					objIndex: rowindex,
					isOpen:false,
					options:item?.options.sort((a, b) => a.order_by - b.order_by)
				}));
				setAllQuestions(modifiedResponse);
				let allIds = []
				let OrderIds = modifiedResponse.map(({ id }) => ({ id }))
				for (let index = 0; index < OrderIds.length; index++) {
					allIds.push(Number(OrderIds[index].id))
				}
				setOriginalIds(allIds);
				//setNewOrderIds(allIds);
			} else {
				setAllQuestions([]);
			}
		}).catch(() => {
			message.error(constants.ErrorMessage);
		});
	}
	const handleDragEnd = (result) => {
		if (!result.destination) {
			return;
		}
		const [reorderedItem] = allQuestions.splice(result.source.index, 1);
		allQuestions.splice(result.destination.index, 0, reorderedItem);
		// Update expandedRows based on new indices
		const newExpandedRows = expandedRows.map((value, index) => {
			return allQuestions[index] !== undefined ? value : false;// Set to false if the row is not found
		});
		let newArr = [];
		allQuestions.forEach((eachQuestion,index)=>{
			eachQuestion.objIndex=index
			newArr.push(Number(eachQuestion?.id));
		})
		setNewOrderIds(newArr);
		setAllQuestions(allQuestions);
		setExpandedRows(newExpandedRows);
	};

	useEffect(()=>{
		if (newOrderIds.length > 0){
			const arrayElementsSame =compareArrays(newOrderIds, originalIds);
			if (arrayElementsSame) {
				setOrderChanged(false);
			} else {
				setOrderChanged(true);
			}
		}else{
			setOrderChanged(false);
		}
	}, [newOrderIds, originalIds])

	const toggleRow = (index) => {
		const currentIndex = allQuestions.findIndex((item) => item?.objIndex === index);
		allQuestions[currentIndex].isOpen=!allQuestions[currentIndex].isOpen;
		if (expandedRows.includes(currentIndex)) {
			setExpandedRows(expandedRows.filter(row => row !== currentIndex));
		} else {
			setExpandedRows([...expandedRows, currentIndex]);
		}
		setAllQuestions(allQuestions)
	}; 

	const getQuestionPreviewContent = ()=>{
		showModal(setShowPreviewModal);
	}
	const saveQuestionOrder = ()=>{
		let payload = null;
		payload={
			changed: newOrderIds,
		}
		fetchApi(`/update-questions-order`, 'POST', payload).then((res) => {
			if (res?.code === '200') {
				message.success(res?.message);
			} else {
				message.error(res?.message);
			}
			getAllQuestions();
		}).catch(() => {
			message.error(constants.ErrorMessage);
		});
	}
	const getQuestionData = (surveyTypeTitle,type,eachObject)=>{
		let htmlData;
		switch (surveyTypeTitle) {
			case 'Courses':
				htmlData = type === '1' ? 
					<Radio.Group>
						<Space direction="vertical">
						{constants.CoursesOptions.map((option)=>(
							<Radio value={option.option_id} className='fw-normal'>{option.option_name}</Radio>
						))}
						</Space>
					</Radio.Group>
					: 
					<div>
						<Input className='formattedInputField mb-3'></Input>
					</div>
				break;
			case 'Instructor':
				htmlData = type === '5' ?
					<span></span>
					:
					<div>
						<Input className='formattedInputField mb-3'></Input>
					</div>
				break;
			default:
				htmlData = type === '1' ?
					<Radio.Group className='mb-3'>
						<Space direction="vertical">
							{eachObject?.options.map((option, index) => {
								return <Radio key={option} value={index} className='fw-normal'>{option?.option_text}</Radio>
							})}
						</Space>
					</Radio.Group>
					:
					<div>
						<Input className='formattedInputField mb-3'></Input>
					</div>
				break;
		}
		return <div className="ps-3 mb-2">{htmlData}</div>
	}

	const clearKeys = () => {}
	const renderMessage = () => (
		<span>{`${constants.AddEditQuestionsNote} `}{/* <a href="javascript:void(0);">Click here to make it Inactive</a> */}</span>
	);
	return (
		<div>
			<BreadcrumbHeader pageTitle={`${constants.SurveyQuestions} - ${surveyDetails?.record?.survey_name}`} breadcrumbItems={breadcrumbItems} />
			<Card className='container-border-custom position-relative h-[calc(100vh-200px)]'>
			<Row>
				<Col>
					<div className="flex justify-between items-center mt-2 gap-3">
						<Space>
							<Button
								type='primary'
								className='text-sm'
								disabled={surveyDetails?.record?.is_active==="1"?true:false}
								onClick={() => {
									setActionType(1);
									setShowAddQuestionModal(true)
								}}>{constants.Add}</Button>
							{orderChanged?
								<Button type='primary' className='text-sm' onClick={saveQuestionOrder}>{constants.SaveOrder}</Button>:null
							}
							<Button type='primary' className='text-sm' disabled={allQuestions.length>0?false:true} onClick={getQuestionPreviewContent}>{constants.Preview}</Button>
							<Button className='backButton' onClick={()=>navigate(-1)}>{constants.Back}</Button>
						</Space>
						{surveyDetails?.record?.is_active==="1"&&
							<div>
								<Alert
									showIcon
									icon={<WarningFilled />}
									type="error"
									message={renderMessage()}
								/>
							</div>}
					</div>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<Card bordered className='position-relative mt-4 mb-3 h-[calc(100vh-230px)] overflow-y-auto'>
					{
						allQuestions.length>0 ?
							<DragDropContext onDragEnd={handleDragEnd}>
								<Droppable droppableId="droppable" direction="vertical" className="mt-3">
									{(provided) => (
										<div ref={provided.innerRef} {...provided.droppableProps} className='grid gap-4'>
											{allQuestions.map((item, index) => (
												<Draggable key={(index + 1).toString()} draggableId={(index + 1).toString()} index={index}>
													{(provided) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															className='rounded-r-lg border-2 border-t-cyan-600 border-r drag-custom bg-slate-100'
															style={{
																...provided.draggableProps.style,
															}}
														>
															<Table
																columns={columns}
																dataSource={[item]}
																pagination={false}
																showHeader={false}
															/>
															{expandedRows.includes(index) && (
																<div>
																	<div className='ps-3 fw-bold my-3' style={{ fontSize: '14px' }}>{item?.question_text}</div>
																	{getQuestionData(surveyDetails?.surveytype, item?.question_type, item)}
																</div>
															)}

														</div>
													)}
												</Draggable>
											))}
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
							:
							<div className="bg-white text-black p-2 text-sm text-center mt-3">No records to display</div>
					}
						{(showAddQuestionModal || showDeleteModal || showEditQuestionModal) &&
							<QuestionContentModal
							type={actionType}
						surveyTitle={surveyDetails?.surveytype}
						deleteQuestion={deleteQuestion}
						setShowDeleteModal={setShowDeleteModal}
						showAddQuestionModal={showAddQuestionModal}
						setShowAddQuestionModal={setShowAddQuestionModal}
						showEditQuestionModal={showEditQuestionModal}
						setShowEditQuestionModal={setShowEditQuestionModal}
						surveyDetails={surveyDetails}
						getAllQuestions={getAllQuestions}
						modalVisible={actionType === 3 ? showDeleteModal : actionType === 2 ? showEditQuestionModal :showAddQuestionModal}
						modalTitle={actionType === 3 ? constants.Confirm : actionType === 2 ? constants.EditQuestionTitle : constants.AddQuestionTitle}
						editQuestionId={editQuestionId}
							setEditQuestionId={setEditQuestionId}
						/>}
						{
							showPreviewModal&&
							getModal(constants.Preview, 
							<PreviewContent
								allQuestions={allQuestions}
								surveyName={surveyDetails?.record?.survey_name}
								surveyTypeTitle={surveyDetails?.surveytype}
								setShowPreviewModal={setShowPreviewModal}
								surveyId={surveyDetails?.record?.id}
								/>, showPreviewModal, closeModal, setShowPreviewModal, () => clearKeys()
							)
						}
					</Card>
				</Col>
			</Row>
			</Card>
		</div>
	)
}

export default Questions
