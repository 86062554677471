import React, { useState, useEffect,useRef } from 'react';
import BreadcrumbHeader from '../Component/BreadcrumbHeader';
import { Card, Form, Button, Table, message, InputNumber } from 'antd';
import { getGpaLevelsAndModifyDataFormat } from '../../GlobalFunctions/GlobalFunctions';
import { fetchStaffApi } from '../../Services/Api';
import constants from '../../Constants/constants';
const AcademicSpecification = () => {
	const [form] = Form.useForm();
	const [academicSpecs, setAcademicSpecs] = useState([]);
	const [gpaPayload, setGpaPayload] = useState([]);

	const fallGpaRefs = useRef([]);
	const springGpaRefs = useRef([]);
	const summerGpaRefs = useRef([]);

	useEffect(() => {
		const preventScroll = (e) => e.preventDefault();

		fallGpaRefs.current.forEach(ref => ref && ref.addEventListener('wheel', preventScroll));
		springGpaRefs.current.forEach(ref => ref && ref.addEventListener('wheel', preventScroll));
		summerGpaRefs.current.forEach(ref => ref && ref.addEventListener('wheel', preventScroll));

		return () => {
			fallGpaRefs.current.forEach(ref => ref && ref.removeEventListener('wheel', preventScroll));
			springGpaRefs.current.forEach(ref => ref && ref.removeEventListener('wheel', preventScroll));
			summerGpaRefs.current.forEach(ref => ref && ref.removeEventListener('wheel', preventScroll));
		};
	}, [academicSpecs]);

	const breadcrumbItems = [
		{
			label: constants.AcademicSpecification,
			active: true
		}
	];
	useEffect(() => {
		getGpaLevelsAndModifyDataFormat(setAcademicSpecs);
	}, [])

	const submitAcademicSpecs = (values) => {
		if (gpaPayload.length>0){
			let payload = null;
			payload = gpaPayload;
		 fetchStaffApi(`/save-gpa-levels`, 'POST', payload).then((res) => {
			 if (res?.code === '200') {
				 message.success(res?.message);
				 getGpaLevelsAndModifyDataFormat(setAcademicSpecs);
				 setGpaPayload([]);
			 }else{
				 message.error(res?.message);
			 }
		 });
		}
	}

	const getFinalValue = (field, key, value) => {
		let semesterType=field === 'fallGpa'?1:field === 'springGpa'?2:3;
		setGpaPayload(prevGpaPayload => [
			...prevGpaPayload.filter(item => !(item.year_in_program === Number(key) && item.semester_type === semesterType)),
			{ year_in_program: Number(key), semester_type: semesterType, gpa: Number(value) }
		]);
	}
	const handleGpaChange = (field, key, value,record, recordIndex) => {
		if (isNaN(value)) {
			value = null;
		}
		const updateRecord = (records) => {
			return records.map(eachRecord => {
				if (eachRecord.key === record.key) {
					return { ...eachRecord, [field]: value };
				}
				return eachRecord;
			});
		};
		const gpaD = updateRecord([...academicSpecs]);
		// Set error flag for GPA value
		let errorField=`${field}Error`;
    gpaD[recordIndex][errorField] = value > 4;
		setAcademicSpecs(gpaD);
		
		getFinalValue(field, key, value)
	};

	const columns = [
				{
					title: constants.Years,
					key: '1',
					width: '30%',
					render: (record) => {
						const yearLabels = {
							'1': 'Freshman',
							'2': 'Sophomore',
							'3': 'Junior',
							'4': 'Senior',
							'5': 'Graduate',
						};
						return yearLabels[record.year_in_program] || '';
					},
				},
				{
					title: (
						<div className="flex justify-center">
							{constants.Semester}
						</div>
					),
					children:[
						{
							title:constants.Fall,
							width: '23%',
							render:(_, record, recordIndex)=>(
								<div>
									<InputNumber
										type="number" 
										min={0} 
										// max={4} 
										step={0.01}
										value={record.fallGpa} 
										//defaultValue={parseFloat(record?.fallGpa)}
										onChange={(value) => handleGpaChange('fallGpa', record.key, value, record, recordIndex)}
										//onBlur={(e) => getFinalValue('fallGpa', record.key, e.target.value)}
										className='academicSpecInput ms-2 mt-2 hide-handle'
										changeOnBlur={false}
										ref={(el) => (fallGpaRefs.current[record.key] = el)}
										onPressEnter={(e) => handleGpaChange('fallGpa', record.key, e.target.value, record, recordIndex)}
									/>
										{record.fallGpaError && 
										<div className="text-xs ms-2 mt-2 text-red-500">{constants.EnterGPAErrorMessage}</div>
										}		
								</div>)
						},
						{
							title: constants.Spring,
							width: '23%',
							render:(_,record, recordIndex)=>(
								<div>
									<InputNumber 
										type="number" 
										min={0} 
										step={0.01}
										value={record.springGpa}
										onChange={(value) => handleGpaChange('springGpa', record.key, value, record, recordIndex)}
										//onBlur={(e) => getFinalValue('springGpa', record.key, e.target.value)}
										className='academicSpecInput ms-2 mt-2 hide-handle'
										changeOnBlur={false}
										ref={(el) => (springGpaRefs.current[record.key] = el)}
										onPressEnter={(e) => handleGpaChange('springGpa', record.key, e.target.value, record, recordIndex)}
									/>
										{record.springGpaError &&
											<div className="text-xs ms-2 mt-2 text-red-500">{constants.EnterGPAErrorMessage}</div>
										}
								</div>)
						},
						{
							title:constants.Summer,
							width: '23%',
							render:(_, record, recordIndex)=>(
								<div>
									<InputNumber
										type="number"  
										min={0} 
										step={0.01} 
										value={record.summerGpa}
										onChange={(value) =>  handleGpaChange('summerGpa', record.key, value, record, recordIndex)}
										className='academicSpecInput ms-2 hide-handle'
										changeOnBlur={false}
										ref={(el) => (summerGpaRefs.current[record.key] = el)}
										onPressEnter={(e) => handleGpaChange('summerGpa', record.key, e.target.value, record, recordIndex)}
									/>
										{record.summerGpaError && 
											<div className="text-xs ms-2 mt-2 text-red-500">{constants.EnterGPAErrorMessage}</div>
										}
								</div>
							)
						}
					]
				}
	];
	const preventFormSubmission = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
		}
	};
	return (
		<div>
			<BreadcrumbHeader pageTitle={constants.AcademicSpecification} breadcrumbItems={breadcrumbItems} />
			<Card className="container-border-custom">
				<Form onFinish={submitAcademicSpecs} form={form} onKeyDown={preventFormSubmission}>
					<Form.Item name="specValues">
						<Table
							dataSource={academicSpecs}
							columns={columns}
							bordered
							size="small"
							className='academicSpecTab records-table'
							pagination={false}
							scroll={{ x: 768, y: 'calc(100vh - 320px)' }}
						/>
					</Form.Item>
					<div className='float-end'>
						<Button type='primary' htmlType='submit' className='text-sm no-padding' disabled={gpaPayload?.length===0}>{constants.Save}</Button>
					</div>
				</Form>
			</Card>
		</div>
	)
}

export default AcademicSpecification
