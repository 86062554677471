import React, { useState, useEffect } from 'react'
import constants from '../../Constants/constants'
import { Table, Checkbox, Flex, Space, Button, message, Card, Spin } from 'antd';
import { fetchStaffApi } from '../../Services/Api';
import { useLocation, useNavigate } from "react-router-dom";
import { COOKIE, getCookie } from '../../Services/Cookie';
import BreadcrumbHeader from '../Component/BreadcrumbHeader';
const Checklist = ({ student_id = getCookie(COOKIE.UserId) }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [tableData, setTableData] = useState([]);
	const [onSaveLoad, setOnSaveLoad] = useState(false);
	const [checkedData, setCheckedData] = useState({});
	const isActive=location?.state?.isActive;
	let isArchived=location?.state?.isArchived;
	const role = getCookie(COOKIE.Role);
	// Question data
	const questionData = [
		{
			question: 'Student completes Advising Form and enters previous semester Grades',
		},
		{
			question: 'Advisor completes Doc. Form indicating that the student was advised. Student and advisor sign the form'
		},
		{
			question: 'Student signs SLP Handbook reviewed'
		},
		{
			question: 'Advisor informs student to register online HP999 for CPR'
		},
		{
			question: 'Advisor informs student to obtain Clearances *CA, CBC, FBI Fingerprint by July 31 prior to Fall of 5th year'
		},
		{
			question: 'Advisor informs student that Health Clearances and Essential Functions forms due by July 31'
		},
		{
			question: 'After advising deadline, Advisor approves REG On-line (Y) for students'
		},
		{
			question: 'Advisor informs students about becoming Tutors and receiving tutoring'
		},
		{
			question: 'Advisor checks Praxis scores and initials scores'
		},
	];

	const enableColumnNumber = (semester_type,year_in_program) => {
		let column_no;
		if(semester_type==="1" && year_in_program==="1"){
			column_no = 1
		}else if((semester_type==="2" && year_in_program==="1") || (parseInt(semester_type) > 2 && year_in_program==="1")){
			column_no = 2
		}else if(semester_type==="1" && year_in_program==="2"){
			column_no = 3
		}else if((semester_type==="2" && year_in_program==="2") || (parseInt(semester_type) > 2 && year_in_program==="2")){
			column_no = 4
		}else if(semester_type==="1" && year_in_program==="3"){
			column_no = 5
		}else if((semester_type==="2" && year_in_program==="3") || (parseInt(semester_type) > 2 && year_in_program==="3")){
			column_no = 6
		}else if(semester_type==="1" && year_in_program==="4"){
			column_no = 7
		}else if((semester_type==="2" && year_in_program==="4") || (parseInt(semester_type) > 2 && year_in_program==="4")){
			column_no = 8
		}else if(semester_type==="1" && year_in_program==="5"){
			column_no = 9
		}else if((semester_type==="2" && year_in_program==="5") || (parseInt(semester_type) > 2 && year_in_program==="5")){
			column_no = 10
		}
		return column_no;
	};

	//function to check on which year transfer student is came.
	const enableTransferColumnNumber = (transfer_semester_type,transfer_year_in_program) => {
		let column_no;
		if(transfer_semester_type==="1" && transfer_year_in_program==="1"){
			column_no = 1
		}else if(transfer_semester_type==="2" && transfer_year_in_program==="1"){
			column_no = 2
		}else if((transfer_semester_type==="1" && transfer_year_in_program==="2") || (parseInt(transfer_semester_type) > 2 && transfer_year_in_program==="1")){
			column_no = 3
		}else if(transfer_semester_type==="2" && transfer_year_in_program==="2"){
			column_no = 4
		}else if((transfer_semester_type==="1" && transfer_year_in_program==="3" )|| (parseInt(transfer_semester_type) > 2 && transfer_year_in_program==="2")){
			column_no = 5
		}else if(transfer_semester_type==="2" && transfer_year_in_program==="3"){
			column_no = 6
		}else if((transfer_semester_type==="1" && transfer_year_in_program==="4") || (parseInt(transfer_semester_type) > 2 && transfer_year_in_program==="3")){
			column_no = 7
		}else if((transfer_semester_type==="2" && transfer_year_in_program==="4")){
			column_no = 8
		}else if((transfer_semester_type==="1" && transfer_year_in_program==="5") || (parseInt(transfer_semester_type) > 2 && transfer_year_in_program==="4")){
			column_no = 9
		}else if((transfer_semester_type==="2" && transfer_year_in_program==="5") || (parseInt(transfer_semester_type) > 2 && transfer_year_in_program==="5")){
			if(parseInt(transfer_semester_type) > 2 && transfer_year_in_program==="5"){
				column_no = 11
			}else{
				column_no = 10
			}
		}
		return column_no;
	};

	const getChecklist = () => {
		fetchStaffApi(`/get-student-checklist/${student_id}`, 'GET').then((res) => {
			if (res?.code === "200") {
				let tableData = [];
				if(res?.data){
					let column_no = enableColumnNumber(res?.data?.semester_type,res?.data?.year_in_program);
					let transfer_user_enable_column_no = enableTransferColumnNumber(res?.data?.transfer_semester,res?.data?.transfer_year);
					setCheckedData(res?.data?.checklist);
					for (let index = 0; index < questionData.length; index++) {
						const element = questionData[index];
						tableData.push({
							key:index,
							enable_column:column_no,
							enable_transfer_column:transfer_user_enable_column_no,
							question:element,
							is_transfer:res?.data?.is_transfer,
							option1:res?.data?.checklist?.[index + 1]?.[1] === "t",
							option2:res?.data?.checklist?.[index + 1]?.[2] === "t",
							option3:res?.data?.checklist?.[index + 1]?.[3] === "t",
							option4:res?.data?.checklist?.[index + 1]?.[4] === "t",
							option5:res?.data?.checklist?.[index + 1]?.[5] === "t",
							option6:res?.data?.checklist?.[index + 1]?.[6] === "t",
							option7:res?.data?.checklist?.[index + 1]?.[7] === "t",
							option8:res?.data?.checklist?.[index + 1]?.[8] === "t",
							option9:res?.data?.checklist?.[index + 1]?.[9] === "t",
							option10:res?.data?.checklist?.[index + 1]?.[10] === "t",
						})
					}
				}
				setTableData(tableData);
			} else {
				setTableData([]);
				setCheckedData({});
			}
		});
	};

	useEffect(() => {
		getChecklist();
	}, []);

	const PointsCols = [
		{
			title: <div className='text-uppercase text-center'>Advising document inventory</div>,
			children: [
				{
					dataIndex: 'key',
					rowScope: 'row',
					key: 'key'
				},
				{
					dataIndex: 'points',
					key: 'points',
					render: (text) => <span className='whitespace-nowrap'>{text}</span>,
				},
				{
					dataIndex: 'brief',
					key: 'brief',
					render: (text) => <span className='whitespace-nowrap'>{text}</span>,
				},
			]
		},
	];
	const pointsTableData = [
		{
			key: 1,
			points: 'Statement of Understanding',
			brief: 'Academic and Clinical Expectations and Standards'
		},
		{
			key: 2,
			points: 'SLP Curriculum Form',
			brief: 'SLP Program 5-year Course of Study',
		},
		{
			key: 3,
			points: 'SLP Blank Curriculum Form',
			brief: 'SLP Program 5-year Course of Study - to be completed by student',
		},
		{
			key: 4,
			points: 'Plan of Study Advisement',
			brief: 'SLP Coursework and Grading Grid',
		},
		{
			key: 5,
			points: 'Advisement Documentation Form',
			brief: 'Document for student faculty advisor notes. More than one form may be used as necessary',
		},
		{
			key: 6,
			points: 'Assessments and Final Review',
			brief: 'Final checklist for faculty advisor and clinical director to complete prior to degree conferral',
		}
	];

	const onchangeValue = (e,key,option) => {
		setTableData(prevArray => {
			const newArray = [...prevArray];
			newArray[key] = {
				...newArray[key],
				["option"+option]:e.target.checked
			}
			return newArray
		});
	};

	const AdvisingChecklistCols = [
		{
			dataIndex: 'question',
			title: <span className='text-uppercase'>Advising checklist</span>,
			render: (text) => <span>{text.question}</span>,
		},
		{
			title: 'Fr Fall',
			dataIndex: 'FreshFall',
			key: 'FreshFall',
			align: 'center',
			render: (_, record, index) => { 
				const greyOut = record?.enable_transfer_column > 1;
				if(greyOut && record?.is_transfer ==="1"){
					return <div className='absolute h-full w-full top-0 left-0 bg-slate-300'></div>;
				}else{
					if (index === 3 || index === 4 || index === 5 || index === 8) {
						return null;
					}
					return <Checkbox disabled={handleDisableColumn(record,1)} onChange={(e) => onchangeValue(e, record?.key, "1")} checked={record.option1} />;
				}
			}
		},
		{
			title: 'Fr Spring',
			dataIndex: 'FreshSpring',
			key: 'FreshSpring',
			align: 'center',
			render: (_, record, index) => { 
				const greyOut = record?.enable_transfer_column>2;
				if(greyOut  && record?.is_transfer ==="1"){
					return <div className='absolute h-full w-full top-0 left-0 bg-slate-300'></div>;
				}else{
					if (index === 3 || index === 4 || index === 8) {
						return null;
					}
					return <Checkbox disabled={handleDisableColumn(record,2)} onChange={(e)=>onchangeValue(e,record?.key,"2")} checked={record.option2} />;
				}
			},
		},
		{
			title: 'So Fall',
			dataIndex: 'SophFall',
			key: 'SophFall',
			align: 'center',
			render: (_, record, index) => { 
				const greyOut = record?.enable_transfer_column>3;
				if(greyOut && record?.is_transfer ==="1"){
					return <div className='absolute h-full w-full top-0 left-0 bg-slate-300'></div>;
				}else{
					if (index === 4 || index === 5 || index === 8 ) {
						return null;
					}
					return <Checkbox disabled={handleDisableColumn(record,3)} onChange={(e)=>onchangeValue(e,record?.key,"3")} checked={record.option3} />;
				}
			},
		},
		{
			title: 'So Spring',
			dataIndex: 'SophSpring',
			key: 'SophSpring',
			align: 'center',
			render: (_, record, index) => { 
				const greyOut = record?.enable_transfer_column>4;
				if(greyOut && record?.is_transfer ==="1"){
					return <div className='absolute h-full w-full top-0 left-0 bg-slate-300'></div>;
				}else{
					if (index === 3 || index === 4 || index === 8) {
						return null;
					}
					return <Checkbox disabled={handleDisableColumn(record,4)} onChange={(e)=>onchangeValue(e,record?.key,"4")} checked={record.option4} />;
				}
			},
		},
		{
			title: 'Jr Fall',
			dataIndex: 'JuniorFall',
			key: 'JuniorFall',
			align: 'center',
			render: (_, record, index) => { 
				const greyOut = record?.enable_transfer_column>5;
				if(greyOut && record?.is_transfer ==="1"){
					return <div className='absolute h-full w-full top-0 left-0 bg-slate-300'></div>;
				}else{
					if (index === 3 || index === 4 || index === 5 || index === 8) {
						return null;
					}
					return <Checkbox disabled={handleDisableColumn(record,5)} onChange={(e)=>onchangeValue(e,record?.key,"5")} checked={record.option5} />;
				}
			},
		},
		{
			title: 'Jr Spring',
			dataIndex: 'JuniorSpring',
			key: 'JuniorSpring',
			align: 'center',
			render: (_, record, index) => { 
				const greyOut = record?.enable_transfer_column>6;
				if(greyOut && record?.is_transfer ==="1"){
					return <div className='absolute h-full w-full top-0 left-0 bg-slate-300'></div>;
				}else{
					if (index === 3 || index === 4 || index === 8) {
						return null;
					}
					return <Checkbox disabled={handleDisableColumn(record,6)} onChange={(e)=>onchangeValue(e,record?.key,"6")} checked={record.option6} />;
				}
			},
		},
		{
			title: 'Sr Fall',
			dataIndex: 'SeniorFall',
			key: 'SeniorFall',
			align: 'center',
			render: (_, record, index) => {
				const greyOut = record?.enable_transfer_column>7; 
				if(greyOut && record?.is_transfer ==="1"){
					return <div className='absolute h-full w-full top-0 left-0 bg-slate-300'></div>;
				}else{
					if (index === 4 || index === 8) {
						return null;
					}
					return <Checkbox disabled={handleDisableColumn(record,7)} onChange={(e)=>onchangeValue(e,record?.key,"7")} checked={record.option7} />;
				}
			},
		},
		{
			title: 'Sr Spring',
			dataIndex: 'SeniorSpring',
			key: 'SeniorSpring',
			align: 'center',
			render: (_, record, index) => { 
				const greyOut = record?.enable_transfer_column>8; 
				if(greyOut && record?.is_transfer ==="1"){
					return <div className='absolute h-full w-full top-0 left-0 bg-slate-300'></div>;
				}else{
					if (index === 4 || index === 7 || index === 8) {
						return null;
					}
					return <Checkbox disabled={handleDisableColumn(record,8)} onChange={(e)=>onchangeValue(e,record?.key,"8")} checked={record.option8} />;
				}
			},
		},
		{
			title: '5th Yr Fall',
			dataIndex: 'fifthFall',
			key: 'fifthFall',
			align: 'center',
			render: (_, record, index) => { 
				const greyOut = record?.enable_transfer_column>9; 
				if(greyOut && record?.is_transfer ==="1"){
					return <div className='absolute h-full w-full top-0 left-0 bg-slate-300'></div>;
				}else{
					if (index === 3 || index === 4 || index === 5 || index === 7) {
						return null;
					}
					return <Checkbox disabled={handleDisableColumn(record,9)} onChange={(e)=>onchangeValue(e,record?.key,"9")} checked={record.option9} />;
				}
			},
		},
		{
			title: '5th Yr Spring',
			dataIndex: 'fifthSpring',
			key: 'fifthSpring',
			align: 'center',
			render: (_, record, index) => { 
				const greyOut = record?.enable_transfer_column>10; 
				if(greyOut && record?.is_transfer ==="1"){
					return <div className='absolute h-full w-full top-0 left-0 bg-slate-300'></div>;
				}else{
					if (index === 3 || index === 4 || index === 5 || index === 7 || index === 8) {
						return null;
					}
					return <Checkbox disabled={handleDisableColumn(record,10)} onChange={(e)=>onchangeValue(e,record?.key,"10")} checked={record.option10} />;
				}
			},
		},
	];

	const handleDisableColumn = (record, column_no) => {
		if(role === "0"){
			return record?.enable_column === column_no ? false: true;
		}else{
			return record?.enable_column >= column_no && !isArchived ? false : true
		}
	};

	const getChecklistResult = () => {
		let temptableData = tableData
		let result = {}
		temptableData.forEach(item=>{
			// Initialize the inner object for the current item
			let innerObject = {};
			// Iterate through options 1 to 10
			for (let i = 1; i <= 10; i++) {
					// Check if the option is true
					if (item[`option${i}`]) {
							// If true, add it to the inner object
							innerObject[i.toString()] = "t";
					}
			}
			// Check if the inner object is not empty
			if (Object.keys(innerObject).length > 0) {
				// Add the inner object to the result object with the key being item key + 1
				result[(item.key + 1).toString()] = innerObject;
			}
		});
		return result;
	};

	const isSaveButtonDisabled = () => {
		let flag = false
		let userCheckedResult = getChecklistResult();
		if(Object.keys(userCheckedResult).length > 0){
			if(JSON.stringify(userCheckedResult) === JSON.stringify(checkedData)){
				flag = true;
			}else{
				flag = false;
			}
		}else{
			if(/* checkedData.length === 0 || */ checkedData===null){
				flag = true;
			}else{
				if(JSON.stringify(checkedData) !== JSON.stringify(userCheckedResult)){
					flag = false;
				}else{
					flag = true;
				}
			}
		}
		return onSaveLoad || flag
	};

	const saveChecklistTable = () => {
		setOnSaveLoad(true)
		let result = getChecklistResult();
		let payload={ 
			student_id: student_id, 
			tickbox: result 
		}
		fetchStaffApi(`/submit-checklist`, 'post',payload).then((res) => {
			if (res?.code === "200") {
				message.success(res?.message);
				getChecklist();
			} else {
				message.success(res?.message);
			}
			setOnSaveLoad(false)
		});
	};

	const breadcrumbItems = [
    { label: constants.Checklist, active: true },
  ];

	return (
		<Spin tip={constants.PleaseWait} spinning={onSaveLoad}>
			<div>
				{
					role === "0" ? (
						<>
							<BreadcrumbHeader
								pageTitle={<span>{constants.Checklist} - {getCookie(COOKIE.FirstName) + " " + getCookie(COOKIE.LastName)}</span>}
								breadcrumbItems={breadcrumbItems}
							/>
							<Card className="container-border-custom dashboard-card border-b-0 rounded-none shadow-none">
								<h4 className='text-center text-uppercase'>{!isArchived ? constants.checklistTitle : constants.checklistTitleForArchived}</h4>
							</Card>
						</>
					) :
						<h4 className='text-center text-uppercase'>{!isArchived ? constants.checklistTitle : constants.checklistTitleForArchived}</h4>
				}
				<div className='bg-white px-3'>
					{!isArchived && <div>
						<Table
							columns={PointsCols}
							dataSource={pointsTableData}
							className="records-table nested-table lg:w-5/6 lg:mx-auto overflow-x-auto" 
							size="small"
							bordered
							pagination={false}
						/>
					</div>}
					<div className='mt-3'>
						<Table
							columns={AdvisingChecklistCols}
							dataSource={tableData}
							className="records-table readable-checkbox overflow-x-auto"
							size="small"
							bordered
							pagination={false}
						/>
					</div>
					{!isArchived && <Flex justify='flex-end' className='mt-3'>
						<div>
							<Space>
								{role !== "0" && (<Button type='primary' danger onClick={() => navigate("/advising-documents")}>{constants.Cancel}</Button>)}
								<Button className="btn-info" disabled={isSaveButtonDisabled()} onClick={saveChecklistTable}>{constants.Save}</Button>
							</Space>
						</div>
					</Flex>}
				</div>
			</div>
		</Spin>
	)
}

export default Checklist