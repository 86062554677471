import React, { useEffect, useState } from "react";
import constants from "../../Constants/constants";
import BreadcrumbHeader from "../Component/BreadcrumbHeader";
import { Button, Card, Col, Flex, Form, Input, Row, Spin, message } from "antd";
import { fetchApi } from "../../Services/Api";
import dayjs from "dayjs";
import { COOKIE, getCookie } from "../../Services/Cookie";
import { scrollUp } from "../Component/FacultyComponents/FacultyFunctions";
import { InfoCircleOutlined } from "@ant-design/icons";

const StudentAdvisorSignup = () => {
  const pageTitle = constants.AdvisorSignup;
  const breadcrumbItems = [
    { label: constants.AdvisorSignup, active: true },
  ];
  const [listData, setListData] = useState([]);
  const [inputsEnabled, setInputsEnabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataloading, setDataLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const userType = getCookie(COOKIE.UserId);
  const fName = getCookie(COOKIE.FirstName);
  const lName = getCookie(COOKIE.LastName);
  const currentDate = dayjs();
  useEffect(() => {
    advisorStudentList();
  }, []);
  const advisorStudentList = () => {
    fetchApi(`/student/fetch-advisor-timings/${userType}`, "GET").then(
      (res) => {
        setLoading(false);
        setListData(res?.data?.length > 0 ? manipulateData(res.data) : []);

        setInputsEnabled(
          !res.data.some((item) =>
            item.students.some(
              (student) =>
                userType === student.student_id && student.student_name
            )
          )
        );
      }
    );
  };

  const manipulateData = (data) => {
    data.forEach((item) => {
      item.students = [];
      item.student_data.studentIds.forEach((studentId, index) => {
        let obj = {
          student_id: studentId,
          student_name: item.student_data.studentNames[index],
          enable:
            userType === studentId || !item.student_data.studentNames[index],
        };
        item.students.push(obj);
      });
    });
    return data;
  };

  const handleChange = (current, dataIndex, studentIndex) => {
    let data = [...listData];
    const clickedStudent = data[dataIndex].students[studentIndex];
    const fullName = `${fName} ${lName}`;

    if (clickedStudent.student_name === fullName) {
      clickedStudent.student_name = "";
      clickedStudent.student_id = "";
    } else {
      clickedStudent.student_name = current.target.value;
      clickedStudent.student_id = current.target.value ? userType : "";
    }

    data.forEach((item) => {
      item.students.forEach((student) => {
        if (student.student_name === fullName && student !== clickedStudent) {
          student.student_name = "";
          student.student_id = "";
        }
      });
    });
    setListData(data);
    setHasChanges(true);
  };

  const handleSaveRecord = () => {
    setDataLoading(true);

    let studentCount = 0;
    let countData = false;
    listData.forEach((item) => {
      item.students.forEach((student) => {
        if (countData) return;
        if (student.student_id === userType) {
          studentCount += 1;
          if (studentCount > 1) {
            countData = true;
            return;
          }
        }
      });
    });

    if (studentCount === 0) {
      scrollUp();
      setDataLoading(false);
      message.error(constants.StudentsFieldError, [7]);
      return;
    }
    if (studentCount > 1) {
      scrollUp();
      setDataLoading(false);
      message.error(constants.StudentErrorMessage, [7]);
      return;
    }

    const student = listData.map((item) => {
      const obj = {};
      item.students.forEach((student, index) => {
        obj[index + 1] =
          student.enable && student.student_name ? student.student_name : "";
      });
      return obj;
    });

    const payload = {
      student_id: userType,
      student: student,
    };
    fetchApi("/student/submit-advisor-timings", "POST", payload).then((res) => {
      if (res.code === "200") {
        advisorStudentList();
        setInputsEnabled(false);
        scrollUp();
        setDataLoading(false);
        message.success(constants.SaveMessage);
      } else {
        message.error(constants.ErrorMessage);
        scrollUp();
        setDataLoading(false);
      }
    });
  };

  const formatTimings = (timings) => {
    const [startTime, endTime] = timings.split(" - ");
    const formattedStartTime = dayjs(startTime, constants.TimeFormat).format(
      "h:mm A"
    );
    const formattedEndTime = dayjs(endTime, constants.TimeFormat).format(
      "h:mm A"
    );
    return `${formattedStartTime} - ${formattedEndTime}`;
  };

  const filteredListData = (advisingDate, timings) => {
    const advisingDateTime = dayjs(
      advisingDate + " " + timings.split(" - ")[0]
    );
    return advisingDateTime.isBefore(dayjs());
  };

  const filteredData = listData.filter((item) => {
    const advisingDate = dayjs(item.advising_date);
    if (advisingDate.isAfter(currentDate, "day")) {
      return true;
    } else if (advisingDate.isSame(currentDate, "day")) {
      const startTime = dayjs(
        advisingDate.format(constants.DateFormat) +
          " " +
          item.timings.split(" - ")[0],
        "MMM DD, YYYY HH:mm"
      );
      return startTime.isAfter(currentDate);
    } else {
      return false;
    }
  });

  const userHasNameInTimeSlots = listData
    .filter(
      (item) =>
        !filteredData.some((filteredItem) => filteredItem.id === item.id)
    )
    .some((item) =>
      item.students.some(
        (student) => student.student_name === `${fName} ${lName}`
      )
    );

  const previouslySelectedTimeSlots = listData
    .filter(
      (item) =>
        !filteredData.some((filteredItem) => filteredItem.id === item.id)
    )
    .map(
      (item) =>
        item.students.some(
          (student) => student.student_name === `${fName} ${lName}`
        ) && (
          <span key={item.id}>
            {dayjs(item.advising_date).format(constants.DateFormat)}
            {"  "}
            {formatTimings(item.timings)}
          </span>
        )
    );

  return (
    <>
      <BreadcrumbHeader
        pageTitle={pageTitle}
        breadcrumbItems={breadcrumbItems}
      />
      <div>
        <Spin tip={constants.PleaseWait} spinning={loading || dataloading}>
          <Card className="container-border-custom">
            {loading ? (
              <div></div>
            ) : filteredData?.length > 0 ? (
              <div>
                <div className="size-full text-base">
                  <InfoCircleOutlined className="input-info " />{" "}
                  {constants.SelectSlotMessage}
                  <hr />
                </div>

                {userHasNameInTimeSlots && (
                  <div className="size-full text-base">
                    {constants.PreviousSlot} {previouslySelectedTimeSlots}
                    <hr />
                  </div>
                )}
                <Form name="form">
                  {listData.map((item, index) => (
                    <div key={item.id}>
                      {!filteredListData(item.advising_date, item.timings) && (
                        <h5>
                          {dayjs(item.advising_date).format(
                            constants.DateFormat
                          )}
                          {"  "}
                          {formatTimings(item.timings)} <hr />
                        </h5>
                      )}
                      <Form.Item
                        hidden={filteredListData(
                          item.advising_date,
                          item.timings
                        )}
                      >
                        <Row gutter={[16, 16]}>
                          {item.students.map((student, studentIndex) => (
                            <Col
                              className="columnbar"
                              span={12}
                              key={studentIndex}
                            >
                              <h6 className="heading6">{studentIndex + 1}.</h6>
                              <Input
                                value={student.student_name}
                                disabled={!inputsEnabled || !student.enable}
                                onClick={() => {
                                  const fullName = `${fName} ${lName}`;
                                  handleChange(
                                    { target: { value: fullName } },
                                    index,
                                    studentIndex
                                  );
                                }}
                              />
                            </Col>
                          ))}
                        </Row>
                      </Form.Item>
                    </div>
                  ))}

                  <Form.Item>
                    <Flex
                      gap="small"
                      align="flex-end"
                      className="action-button-container"
                    >
                      <Flex gap="small" wrap="wrap">
                        {!inputsEnabled && (
                          <Button
                            type="primary"
                            className="action-btn"
                            danger
                            onClick={() => setInputsEnabled(true)}
                          >
                            {constants.Reschedule}
                          </Button>
                        )}
                        {inputsEnabled && (
                          <Button
                            type="primary"
                            className="action-btn"
                            onClick={handleSaveRecord}
                            disabled={!hasChanges}
                          >
                            {constants.Save}
                          </Button>
                        )}
                      </Flex>
                    </Flex>
                  </Form.Item>
                </Form>
              </div>
            ) : (
              <div className="signup-border-color">
                {constants.NoDataMessage}
              </div>
            )}
          </Card>
        </Spin>
      </div>
    </>
  );
};

export default StudentAdvisorSignup;
