import React, { useContext, useState } from "react";
import {
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	UserOutlined,
	WarningFilled,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import {
	Layout,
	Button,
	Avatar,
	Space,
	Popover,
	Card,
	Flex,
	Badge,
	Row,
	Col,
	List,
	Typography
} from "antd";
import { UserContext } from "../../Context";
import { getCookie, COOKIE, deleteCookie } from "../../Services/Cookie";
import constants from "../../Constants/constants";
import { Link, useNavigate } from "react-router-dom";
import { fetchApi } from "../../Services/Api";
import { notificationHandler } from "../../GlobalFunctions/GlobalFunctions";

const { Header } = Layout;

const Headers = ({ countData }) => {
	const navigate = useNavigate();
	// const profileImg = sessionStorage.getItem("profile_image") ? sessionStorage.getItem("profile_image") : localStorage.getItem("profile_image");
	const { collapsed, profileImage, toggleCollapsed } = useContext(UserContext);
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [visible, setVisible] = useState(false);
	const userRoleId = getCookie(COOKIE.Role);
  const isStudent = userRoleId === '0'
	const { Paragraph } = Typography;
	const cookiesToDelete = [
		COOKIE.UserId,
		COOKIE.UserName,
		COOKIE.FirstName,
		COOKIE.MiddleName,
		COOKIE.LastName,
		COOKIE.Token,
		COOKIE.FacultyIdFromEdit,
		COOKIE.IsAdvisor,
		COOKIE.Role,
		COOKIE.IsActive,
	];
	const logOut = () => {
		const payload = { is_logged_in: 0, is_staff: 1 };
		fetchApi(`/logout/${getCookie(COOKIE.UserId)}`, "POST", payload)
			.then((res) => {
				// console.log(res);
			})
			.catch((error) => { });
		cookiesToDelete.forEach((record) => deleteCookie(record, "/"));
		sessionStorage.clear();
		localStorage.clear();
		window.location.href = constants.BaseUrl + "/staff-login";
	};

	const navigateToModule = (destination) => {
		navigate(`/${destination}`, {
			state: { editrecord: getCookie(COOKIE.UserId), isProfile: true },
		});
		setIsPopoverOpen(false);
	};
	const profileMenu = (
		<div className="profile-main">
			<Card className="profileCard text-center">
				{profileImage ? (
					<Avatar size={64} icon={<img src={profileImage} alt="profile" />} />
				) : (
					<Avatar size={64} icon={<UserOutlined />} />
				)}
				<br></br>
				<span className="profile-name">
					{getCookie(COOKIE.FirstName)} {getCookie(COOKIE.LastName)}
				</span>
			</Card>
			<Flex gap={"small"} horizontal className="profile-menu-footer">
				<Button
					className="bgGreen500"
					onClick={() => navigateToModule("profile")}
				>
					{constants.Profile}
				</Button>
				<Button onClick={() => navigateToModule("change-password")} className="change-password-btn">
					{constants.ChangePassword}
				</Button>
				<Button onClick={logOut} type="primary" danger>
					{constants.SignOut}
				</Button>
			</Flex>
		</div>
	);
	const handleClosePopover = () => {
		setVisible(false);
	};
	const handleNotificationClick = (notif) => {
		notificationHandler(
			notif, 
			navigate, 
			{ isStudent }
		)
		setVisible(false);
	}

	const renderItemHeader = (item) => {
		return (
			<List.Item>
				<Row>
					<Col span={24}>
						<Space className="!items-start">
							{item.priority === "1" ? (
								<WarningFilled className="error-icon" />
							) : item.priority === "2" ? (
								<WarningFilled className="input-info" />
							) : (
								<WarningFilled className="partial-error" />
							)}
							<div className="partial-message">
								{item.notification_url ? (
									<span
										onClick={() => handleNotificationClick(item)}
										className="cursor-pointer"
									>
										{item.notification_text_details}
									</span>
								) : (
									<Link to="/notifications">
										{/* <span>{item?.notification_text_details}</span> */}
										<Paragraph
											ellipsis={{
												rows: item?.notification_text_details.length > 20 ? 2 : 1,
												onEllipsis: (ellipsis) => {

												},
											}}
											className="!mb-1"
											style={{ color: '#1677ff' }}
										>
											{item?.notification_text_details}
										</Paragraph>
									</Link>
								)}
							</div>
						</Space>
					</Col>
				</Row>
			</List.Item>
		);
	};
	const loadMoreFunction = () => (
		<div className="view-button">
			<Button type="link" onClick={handleClosePopover}>
				<Link to="/notifications">{constants.ViewMore}</Link>
			</Button>
		</div>
	);

	return (
		<Header className="main-header">
			<Button
				type="text"
				icon={
					collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
				}
				onClick={toggleCollapsed}
				className="text-white menu-btn !invisible md:!visible"
			/>
			<Space>
				{countData?.length > 0 ? (
					<Popover
						overlayClassName="notification-popover"
						content={
							<List
								size="large"
								className="list-size"
								bordered
								dataSource={countData?.slice(0, 5)}
								renderItem={renderItemHeader}
								loadMore={loadMoreFunction()}
							/>
						}
						trigger="click"
						open={visible}
						onOpenChange={setVisible}
					>
						<Button type="text" className="menu-btn">
							<Badge
								count={countData?.length}
								size="small"
								showZero={true}
								className="badge-style"
							>
								<FontAwesomeIcon icon={faBell} className="text-white text-lg" />
							</Badge>
						</Button>
					</Popover>
				) : (
					<Button type="text" className="menu-btn">
						<Badge
							count={countData?.length}
							size="small"
							showZero={true}
							className="badge-style"
						>
							<FontAwesomeIcon icon={faBell} className="text-white text-lg" />
						</Badge>
					</Button>
				)}

				<Popover
					content={profileMenu}
					trigger="click"
					overlayClassName="profile-popover"
					open={isPopoverOpen}
					onOpenChange={setIsPopoverOpen}
				>
					<Button type="text" className="text-white menu-btn">
						<Space>
							{profileImage ? (
								<Avatar icon={<img src={profileImage} alt="profile" />} />
							) : (
								<Avatar icon={<UserOutlined />} />
							)}
							<span className="text-white">
								{getCookie(COOKIE.FirstName)} {getCookie(COOKIE.LastName)}
							</span>
						</Space>
					</Button>
				</Popover>
			</Space>
		</Header>
	);
};

export default Headers;
