import React, { useEffect, useState } from "react";
import constants from "../../../Constants/constants";
import BreadcrumbHeader from "../../Component/BreadcrumbHeader";
import { Button, Card, Input, Space, Spin, Table } from "antd";
import { getNextAndPreviousButtons, handleRowSelect, performSearch, showTotalItems, CustomSortIcon, HighlightedText } from "../../../GlobalFunctions/GlobalFunctions";
import { COOKIE, getCookie } from "../../../Services/Cookie";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { fetchApi } from "../../../Services/Api";

const sorterTooltipStyle = {
	placement: 'top',
	align: { offset: [10, 5] },
}

function getRoute() {
  let splitRoute = window.location?.pathname?.split('/')
  return `/${splitRoute[splitRoute?.length -1]}`
}

const LearningOutcomesList = () => {
  const pageTitle = constants.LearningOut;
  const breadcrumbItems = [{ label: constants.LearningOut, active: true }];

  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [listData, setListData] = useState([]); //IF LEARNING OUTCOME SET DATA DIRECTLY FROM LOCATION STATE
  const [filteredData, setFilteredData] = useState([]);
  const [rowData, setRowData] = useState([]);
	const [rowKeys, setRowKeys] = useState([]);
  const [viewButtonDisabled, setViewButtonDisabled] = useState(true);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const ID = parseInt(getCookie(COOKIE.UserId))
  const navigate = useNavigate();

  useEffect(() => {
    getSurveyList()
  }, [])

  const getSurveyList = () => {
    setLoading(true)
    fetchApi(`/staff/get-shared-learning-outcomes-results/${ID}`, 'get').then((res)=> {
      setLoading(false)
      if(res?.data?.length > 0) {
        setListData(res?.data)
        setFilteredData(res?.data)
        return
      }
      setListData([])
      setFilteredData([])
    }).catch((err) => setLoading(false))
  }

  const onSearch = (event) => {
    const search = event.target.value;
    setSearchValue(search);
    const fieldNames = [
      "survey_name"
    ];
    const searchedValue = performSearch(search, [...filteredData], fieldNames);
    setListData(searchedValue);
  };

  const onSelectChange = (newSelectedRowKeys = [], selectedRowData = [], record = null, index = null) => {
    handleRowSelect(
			newSelectedRowKeys, //KEYS FROM ON SELECT CHANGE
			selectedRowData, //RECORDS FROM ON SELECT CHANGE
			record, //CURRENT SELECTED RECORD
			index, //CURRENT SELECTED RECORD INDEX
			{ rowKeys: rowKeys, rowData: rowData }, //GET STATES FOR KEYS AND RECORDS
			{ setRowKeys: setRowKeys, setRowData: setRowData }, //SET STATES FOR KEYS AND RECORDS
		)
    setSelectedRecord(record)
    setViewButtonDisabled(newSelectedRowKeys?.length === 0)
  };

  const rowSelection = {
    hideSelectAll: true,
    type: "checkbox",
    selectedRowKeys: rowKeys,
    onSelect: (record, selected, selectedRows) => onSelectChange( 
			// selectedRows?.map((item) => item.id), //RETRIEVE ONLY IDs
			// selectedRows, //ARRAY OF RECORDS WHICH ARE SELECTED
      null,
      null,
			record, //CURRENT SELECTED RECORD
			listData?.findIndex((item) => item.id === record.id) //INDEX OF THE CURRENTLY SELECTED RECORD FROM TABLE DATA
		),
  };

  const pagination = {
    pageSize: constants.TablePageSize,
    showTotal: (total, range) => showTotalItems(total, range, listData),
    itemRender: getNextAndPreviousButtons,
  }

  const onRow = (record, rowIndex) => {
    return {
      onClick: () => onSelectChange(null, null, record, rowIndex),
      onDoubleClick: () => handleDoubleRowClick(record),
    }
  }

  const handleDoubleRowClick = (record) => {
    navigate('/survey-result', { state: { selectedRecord: record } });
  }

  const columns = [
    {
      title: `${constants.SurveyTitle}`,
      dataIndex: "survey_name",
      key: "survey_name",
      width: '100%',
      sorter: (a, b) => a?.title?.localeCompare(b?.title),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
			showSorterTooltip: sorterTooltipStyle,
      render:(_, { survey_name })=> survey_name ? <HighlightedText text={survey_name} highlight={searchValue} /> : '-'
    }
  ]

  return (
    <>
      <BreadcrumbHeader
        pageTitle={pageTitle}
        breadcrumbItems={breadcrumbItems}
      />
      <div>
        <Card className="container-border-custom">
          <div className="d-flex justify-content-between">
            <Space wrap>
              <Link
                to='/survey-result'
                state={{ 
                  selectedRecord,
                  previousRoute: getRoute(),
                }}
              >
                <Button
                  className="text-capitalize btn-info"
                  disabled={viewButtonDisabled}
                >
                  {constants.View}
                </Button>
							</Link>
            </Space>
            <Input
              className="w-48"
              placeholder={constants.Search}
              onChange={onSearch}
              value={searchValue}
            />
          </div>
          <div className="mt-2">
            <Spin tip={constants.PleaseWait} spinning={loading}>
              <Table
                onRow={(record, rowIndex) => onRow(record, rowIndex)}
                className="records-table overflow-auto data_table"
                rowSelectedBg="#e6f4ff"
                columns={columns}
                dataSource={listData}
                rowKey='id'
                bordered
                size="small"
                rowSelection={rowSelection}
                scroll={{ x: 768 ,y: 'calc(100vh - 300px)'}}
                pagination={pagination}
                searchableProps={{ fuzzySearch: true }}
              />
            </Spin>
          </div>
        </Card>
      </div>
    </>
  );
}
 
export default LearningOutcomesList;