import React, { useEffect, useState } from 'react';
import BreadcrumbHeader from '../Component/BreadcrumbHeader';
import constants from '../../Constants/constants';
import { Button, Card, Input, Space, Table, Form, Modal, Flex, message } from 'antd';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchStaffApi, fetchStaffDownloadApi, fetchStaffFormData } from '../../Services/Api';
import { Editor } from '@tinymce/tinymce-react';
import { HighlightedText, beforeUploadCheckFileType, getNextAndPreviousButtons, handleRowSelect, performSearch, showTotalItems,removeExtraSpaces, CustomSortIcon, sortTwoNumbers, checkEmptySpace, handleValidateTextEditor } from '../../GlobalFunctions/GlobalFunctions';
import FileUpload from '../Component/FileUpload';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { COOKIE, getCookie } from '../../Services/Cookie';

const ConfidentialNotes = () => {

	useEffect(() => {
		getConfidentialNotes();
	}, []);

	const choseFileBtn = (<Button icon={<UploadOutlined />}>{constants.ChooseFile}</Button>);
	const changeFileBtn = (<Button danger>{constants.ChangeFile}</Button>);

	const location = useLocation();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [rowKeys, setRowKeys] = useState([]);
	const [rowData, setRowData] = useState([]);
	const [saveDisabled, setSaveDisabled] = useState(false);
	const [deleteDisabled, setDeleteDisabled] = useState(false);
	const [studentName, setStudentName] = useState("");
	const [tableData, setTableData] = useState([]);
	const [filterTableData, setFilterTableData] = useState([]);
	const [showModal, setShowModal] = useState({
		add: false,
		edit: false,
		delete: false
	});
	const [uploadFileList, setUploadFileList] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [renderUploadBtn, setRenderUploadBtn] = useState(choseFileBtn);

	const breadcrumbItems = [
		{ label: constants.StudentListing, href: '/student-listing' },
		{ label: constants.ConfidentialNotes, active: true }
	];

	const columns = [
		{
			title: constants.Title,
			dataIndex: 'title',
			key: 'title',
			render: (title) => <HighlightedText text={title} highlight={searchValue} />,
			sorter: (a, b) => a?.title?.localeCompare(b?.title),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
		{
			title: constants.AddedBy,
			dataIndex: 'name',
			key: 'added_by',
			render: (added_by) => <HighlightedText text={added_by} highlight={searchValue} />,
			sorter: (a, b) => a?.name?.localeCompare(b?.name),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
		{
			title: constants.Date,
			dataIndex: 'modifiedDate',
			key: 'dt_created',
			render: (modifiedDate) => <HighlightedText text={modifiedDate} highlight={searchValue} />,
			sorter: (a, b) => sortTwoNumbers(a,b,"modifiedDate",true),
			sortIcon: ({ sortOrder }) => <CustomSortIcon sortOrder={sortOrder} />,
		},
		{
			title: constants.Download,
			dataIndex: 'upload_name',
			key: 'upload_name',
			align: 'center',
			render: (upload_name, record) => <span>{upload_name ? <DownloadOutlined onClick={() => downloadFile(record)} /> : null}</span>
		}
	];

	const onSelectChange = (newSelectedRowKeys = [], selectedRowData = [], record = null, index = null) => {
		if (newSelectedRowKeys?.length > 1) {
			newSelectedRowKeys = [newSelectedRowKeys[newSelectedRowKeys.length - 1]];
			selectedRowData = selectedRowData.slice(-1);
		}
		handleRowSelect(
			newSelectedRowKeys, //KEYS FROM ON SELECT CHANGE
			selectedRowData, //RECORDS FROM ON SELECT CHANGE
			record, //CURRENT SELECTED RECORD
			index, //CURRENT SELECTED RECORD INDEX
			{ rowKeys: rowKeys, rowData: rowData }, //GET STATES FOR KEYS AND RECORDS
			{ setRowKeys: setRowKeys, setRowData: setRowData }, //SET STATES FOR KEYS AND RECORDS
		)
  };

	const rowSelection = {
    type: 'checkbox',
		columnWidth: '5%',
    onChange: onSelectChange,
		selectedRowKeys:rowKeys
  };

	const getConfidentialNotes = () => {
		fetchStaffApi(`/confidential-notes-listing?sid=${location.state?.student_id}`, 'get').then((res) => {
			if (res?.code === "200") {
				setRowKeys([]);
				//adding key item for row selection
				const modifiedData = res?.data?.notes?.map((item, index) => ({
					...item,
					key: index + 1,
					name: item?.faculty_first_name + " " + item?.faculty_last_name,
					modifiedDate: item?.dt_created ? dayjs(item?.dt_created).format(constants.DateFormat) : null
				}));
				setTableData(modifiedData);
				setFilterTableData(modifiedData);
				setStudentName(res?.data?.student_name);
			} else {
				setTableData([]);
				setStudentName("");
			}
		});
	};

	const handleAdd = () => {
		form.resetFields();
		setUploadFileList([]);
		setShowModal(prevState => ({
			...prevState,
			add: true
		}));
	};

	const handleDelete = () => {
		form.resetFields();
		setShowModal(prevState => ({
			...prevState,
			delete: true
		}));
	};

	const getConfidentialNote = (id) => {
		fetchStaffApi(`/get-confidential-note/${id}`, 'get').then((res)=>{
			if(res.code === "200"){
				form.setFieldsValue({ title: res?.data?.title });
				handleEditorChange(res?.data?.details, 'description', 'editor_text')
				
				if (res?.data?.upload_name) {
					setUploadFileList([{ id: res?.data?.id, name: res?.data?.upload_name }]);
					setRenderUploadBtn(changeFileBtn);
				} else {
					setUploadFileList([]);
					setRenderUploadBtn(choseFileBtn);
				}
				handleEdit();
			}else{
				message.error(res?.message);
			}
		});
	};

	const handleEdit = () => {
		setShowModal(prevState => ({
			...prevState,
			edit: true
		}));
	};

	const handleButtonClick = (btn_type) => {
		if (btn_type === "add") {
			setRowKeys([]);
			handleAdd();
			setRenderUploadBtn(choseFileBtn);
		} else if (btn_type === "edit") {
			let value = rowData[0];
			getConfidentialNote(value?.id);
		} else if (btn_type === "delete") {
			handleDelete();
		}
	};

	//function to handle file's change.
	const handleFilesChange = (info) => {
		if (info?.fileList) {
			let checkBeforeUpload = beforeUploadCheckFileType(info?.file,false);
			if(checkBeforeUpload){
				setUploadFileList(info.fileList);
			}
		}
	};

	//Removing all null values.
	function filterNonNullValues(obj) {
		const filteredObj = {};
		Object.keys(obj).forEach((key) => {
			const value = obj[key];
			if (value !== null && value !== undefined && value !== '') {
				filteredObj[key] = value;
			}
		});
		return filteredObj;
	};

	const handleSave = (value, type) => {
		let role_id = getCookie(COOKIE.UserId);
		if (type === "add") {
			setSaveDisabled(true)
			let payload = {
				student_id: location.state?.student_id,
				title: removeExtraSpaces(value?.title),
				notedesc: removeExtraSpaces(value?.editor_text),
				role: role_id,
			}
			const filteredObject = filterNonNullValues(payload);
			handleUpload("/add-confidential-note", "post", filteredObject, type);
		} else if (type === "edit") {
			setSaveDisabled(true)
			let payload = {
				student_id: location.state?.student_id,
				title: removeExtraSpaces(value?.title),
				notedesc: value?.editor_text ? removeExtraSpaces(value?.editor_text) : removeExtraSpaces(rowData?.[0]?.details),
				role: role_id,
			}
			const filteredObject = filterNonNullValues(payload);
			handleUpload(`/edit-confidential-note/${rowData[0]?.id}`, "post", filteredObject, type);
		} else {
			setDeleteDisabled(true)
			handleUpload(`/delete-confidential-note/${rowData[0]?.id}`, "delete", null, type);
		}
	};

	const handleUpload = async (url, method, payload, type) => {
		try {
			const formData = new FormData();
			//Appending Upload file.
			uploadFileList.forEach((file) => {
				if (file.originFileObj) {
					formData.append('file', file.originFileObj);
				}
			});
			// Append array parameter (other form values)
			if (payload !== null) {
				Object.entries(payload).forEach(([key, value]) => {
					formData.append(`${key}`, value);
				});
			}
			fetchStaffFormData(url, method, formData).then((res) => {
				if (res.code === "200") {
					message.success(res?.message);
					closeModal(type);
					getConfidentialNotes();
					setUploadFileList([]);
				} else {
					message.error(res?.message);
				}
				setSaveDisabled(false)
				setDeleteDisabled(false)
			}).catch((err) => {
				setSaveDisabled(false)
				setDeleteDisabled(false)
			});
		}
		catch (error) {
			setSaveDisabled(false)
			setDeleteDisabled(false)
			message.error(error.message);
		}
	};
	//api route to be changed
	const deleteFile = (file) => {
		setDeleteDisabled(true)
		fetchStaffApi(`/delete-confidential-document/${file?.id}`, 'delete').then((res) => {
			if (res?.code === "200") {
				message.success(res?.message);
				getConfidentialNote(file?.id);
			} else {
				message.error(res?.message);
			}
			setDeleteDisabled(false)
		}).catch((err) => {
			setDeleteDisabled(false)
		});
	};

	const handleEditorChange = (content, editField, hiddenField) => {
		form.setFieldsValue({ 
			[editField]: content || '',
			[hiddenField]: content || '',
		})
	};

	const modalComponent = (title, open, type) => {
		return <Modal
			title={title}
			open={open}
			footer={null}
			cancelText={constants.Close}
			onCancel={() => closeModal(type)}
			className='!top-5'
		>
			<Form layout="vertical" form={form} onFinish={(value) => handleSave(value, type)}>
				{
					type === "delete" ? <div className='faculty-form-item'><strong className="text-base">{constants.DeleteConfidentialNotesAlrt}</strong></div> :
						<>
							<Form.Item
								name="title"
								label={constants.Title}
								rules={[
									{
										required: true,
										message: constants.RequiredField,
										whitespace: true,
									},
								]}
							>
								<Input
									onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue('title'))}
								/>
							</Form.Item>
							<Form.Item
								name="description"
								className='addNotificationEditor'
								label={constants.Description}
								rules={[
									{
										required: true,
										message: ' ',
									},
									{ validator: (rule, value, validator) => handleValidateTextEditor(rule, value, validator, 'editor_text', form) },
								]}
							>
								<Editor
									tinymceScriptSrc={constants.TextEditorSrcPath}
									init={{
										height: 170,
										menubar: false,
										branding: false,
										plugins: constants.TinyMcePlugins,
										toolbar: constants.TinyMceToolbar,
										content_style: constants.TinyMceContentStyle,
										placeholder: constants.TinyMcePlaceholder,
										toolbar_mode: 'sliding'
									}}
									onEditorChange={(e) => handleEditorChange(e, 'description', 'editor_text')}
									onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue('editor_text'))}
								/>
							</Form.Item>
							<Form.Item 
                name='editor_text'
                hidden>
              </Form.Item>
							<Form.Item
								label={constants.UploadFile}
								name="upload_file"
							>
								<FileUpload
									renderContent={renderUploadBtn}
									name='file'
									listType='text'
									className=''
									showUploadList={true}
									isMultiple={false}
									beforeUpload={beforeUploadCheckFileType}
									onChange={handleFilesChange}
									fileCount={1}
									defaultFileList={uploadFileList.length > 0 ? uploadFileList : []}
									onRemoveFile={deleteFile}
								/>
								<div className='mt-1.5'>{constants.ValidFileType}</div>
								<div className='mt-1.5'>{constants.ValidFileSize}</div>
							</Form.Item>
						</>
				}
				<Flex justify='flex-end'>
					<Space>
						<Form.Item >
							<Button
								type='primary'
								size="middle"
								className="text-capitalize"
								onClick={() => closeModal(type)}
								danger
							>
								{constants.Close}
							</Button>
						</Form.Item>
						<Form.Item >
							{type === "delete" ?
								<Button
									type='primary'
									size="middle"
									className="text-capitalize"
									loading={deleteDisabled}
									disabled={deleteDisabled}
									onClick={() => handleSave(null, 'delete')}
								>
									{constants.Delete}
								</Button>
								:
								<Button
									type='primary'
									size="middle"
									className="text-capitalize"
									htmlType="submit"
									loading={saveDisabled}
									disabled={saveDisabled}
								>
									{constants.Save}
								</Button>
							}
						</Form.Item>
					</Space>
				</Flex>
			</Form>
		</Modal>
	};

	const closeModal = (modalname,val) => {
		// getConfidentialNotes();
		setShowModal(prevState => ({
			...prevState,
			[modalname]: false
		}));
	};

	const downloadFile = (file) => {
		let fileExtention = file?.upload_name?.split('.').pop();
		fetchStaffDownloadApi(`/download-confidential-note/${file?.id}`, "get", null).then(
			(res) => {
				if (res) {
					let url, a;
					url = window.URL.createObjectURL(
						new Blob([res], { type: "application" }),
						{ type: `data:attachment/${fileExtention}` }
					);
					a = document.createElement("a");
					a.download = file?.upload_name;
					a.href = url;
					a.dispatchEvent(new MouseEvent("click"));
				} else {
					message.error("Failed to download");
				}
			}
		);
	};

	const onSearch = (event) => {
		const search = event.target.value;
		setSearchValue(search);
		const searchFieldNames = [
			'title',
			'name',
			'modifiedDate',
		];
		let searchedValue = performSearch(search, filterTableData, searchFieldNames);
		setTableData(searchedValue);
	};

	return (
		<div>
			<BreadcrumbHeader pageTitle={<span>{constants.ConfidentialNotes} - {studentName}</span>} breadcrumbItems={breadcrumbItems} />
			<Card className="container-border-custom">
				<div className='p-2'>
					<div className='d-flex justify-content-between'>
						<Space size={4} wrap>
							<Button className="text-capitalize btn-info" disabled={rowKeys.length === 1 ? true : false}onClick={() => handleButtonClick("add")}>{constants.Add}</Button>
							<Button className="text-capitalize btn-info" disabled={rowKeys.length === 1 ? false : true} onClick={() => handleButtonClick("edit")}>{constants.Edit}</Button>
							<Button className="text-capitalize btn-info" disabled={rowKeys.length === 1 ? false : true} onClick={() => handleButtonClick("delete")}>{constants.Delete}</Button>
							<Button className="text-capitalize" onClick={() => navigate('/student-listing')}>{constants.Back}</Button>
						</Space>
						<Input className="w-48 h-fit" size="middle" onChange={onSearch} placeholder={constants.Search} />
					</div>
					<Table
						onRow={(record, rowIndex) => {
							return {
								onClick: () => onSelectChange(null, null, record, rowIndex),
							}
						}}
						className="records-table mt-2"
						rowSelection={{...rowSelection,hideSelectAll:true}}
						columns={columns}
						dataSource={tableData}
						bordered
						size="small"
						pagination={{
							pageSize: constants.TablePageSize,
							showTotal: (total, range) => showTotalItems(total, range, tableData),
							itemRender: getNextAndPreviousButtons,
						}}
						rowKey='id'
					/>
				</div>
			</Card>
			{showModal.add ? modalComponent(constants.AddConfidentialNotes, showModal.add, "add"): null}
			{showModal.edit ? modalComponent(constants.EditConfidentialNotes, showModal.edit, "edit"): null}
			{showModal.delete ? modalComponent(constants.DeleteConfidentialNotes + " - " + rowData[0]?.title, showModal.delete, "delete"): null}
		</div>
	)
}

export default ConfidentialNotes;