import React, { useEffect, useState } from "react";
import BreadcrumbHeader from "../Component/BreadcrumbHeader";
import { Card } from "antd";
import { COOKIE, getCookie } from "../../Services/Cookie";
import { fetchStaffApi } from "../../Services/Api";
import constants from "../../Constants/constants";

const pageTitle = constants.ActiveCourses;
const breadcrumbItems = [{ label: constants.ActiveCourses, href: '/active-course-list', active: true },]
const columns = ['Course', 'Section', 'Faculty Assigned', 'Students']
const userRoleId = getCookie(COOKIE.Role);
const isStudent = userRoleId === '0'

const ActiveCourseList = () => {
  const userId = getCookie('userId')
  const [activeCourseList, setActiveCourseList] = useState([])

  useEffect(() => {
    getActiveCourses()
  }, [])

  const getActiveCourses = async () => {
    let key = isStudent ? 'student_id' : 'admin_id'
    let payload = {
			[key]: userId,
		}
		fetchStaffApi('/view-course-details', 'POST', payload).then((res) => {
			if (res?.code === "200") {
				setActiveCourseList(res?.data?.length > 0 ? res.data : [])
        return
			}
			setActiveCourseList([])
		}).catch(() => {
      setActiveCourseList([])
    });
  }

  return (
    <div>
			<BreadcrumbHeader pageTitle={pageTitle} breadcrumbItems={breadcrumbItems} />
      <Card className="mb-3 container-border-custom dashboard-card">
        <Card
            title={`Active Courses for this Semester`}
            className="dashboard-card-head"
          >
            <table className="w-full border-collapse">
              <tr>
                {
                  columns.map((column, index) => (
                    <th key={index} className="border border-gray-500 bg-[#3c8dbc] text-white font-medium p-2">{column}</th>
                  ))
                }
              </tr>
              {
                activeCourseList?.map((course) => (
                  <>
                    {
                      course?.sections?.map((section, index) => (
                        <tr key={index}>
                          {index === 0 && <th rowspan={course.sections?.length} className="border border-gray-500 p-2 font-semibold">{course.course_name}</th>}
                          <td className="border border-gray-500 p-2 text-right w-16">{section.section_no}</td>
                          <td className="border border-gray-500 p-2">{section.faculty_name}</td>
                          <td className="border border-gray-500 p-2">
                            {section?.student_names?.map((student) => (
                              <>
                                <strong>{student.year}: </strong><>{student.list?.join(', ')}</><br />
                              </>
                            ))}
                          </td>
                        </tr>
                      ))
                    }
                  </>
                ))
              }
          </table>
        </Card>
      </Card>
    </div>
  );
}
 
export default ActiveCourseList;