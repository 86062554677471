import React, { useState,useEffect } from 'react';
import { Input, Select, Button, Form, Row, Col, Table, DatePicker, Switch, Checkbox, Modal, message } from 'antd';
import constants from '../../../Constants/constants';
import dayjs from 'dayjs';
import { fetchStaffApi } from '../../../Services/Api';
import { checkEmptySpace, closeModal, removeExtraSpaces } from '../../../GlobalFunctions/GlobalFunctions';
import { COOKIE, getCookie } from '../../../Services/Cookie';

const AddAndEditSurveys = ({isAddOrEdit,setShowAddModal,surveyTypes,clearKeys,isHiddenSurvey,setShowEditModal,selectedRecord,showAddModal,showEditModal,continueSave,setContinueSave,setNewAddedRecord}) => {
  const [form] = Form.useForm();
  const [showCourses, setShowCourses] = useState(false);
	const [visibilityValue, setVisibilityValue] = useState(false);
  const [disabledSurveyName, setDisabledSurveyName] = useState(false);
  const [surveyType, setSurveyType] = useState('');
	const [courseOrInstructorType,setCourseOrInstructorType] = useState('');
	const [courseName, setCourseName] = useState('');
	const [allCourses,setAllCourses] = useState([]);
	const [surveyName, setSurveyName] = useState('');
	const [showVisibleActive, setShowVisibleActive] = useState(false);
	const [buttonClicked, setButtonClicked] = useState(null);
	const [loading,setLoading] = useState(false);
	const [courseLoading,setCourseLoading] = useState(false);
	const [refresh,setRefresh] = useState(false);

  useEffect(()=>{
	let selectedSurvey = surveyTypes.filter((each) => (each?.id === selectedRecord?.survey_type ? each.content : ''))
	setSurveyType(selectedSurvey[0]?.content)
  if(isAddOrEdit==="edit"){
    form.setFieldsValue({
      survey_name: selectedRecord?.survey_name,
      actual_course: (surveyType === 'Courses' || surveyType === 'Instructor') ? selectedRecord?.course_id : null,
      survey_type: selectedRecord?.survey_type,
      start_date: selectedRecord?.start_date !== null?dayjs(selectedRecord?.start_date):'',
      end_date: selectedRecord?.end_date !== null?dayjs(selectedRecord?.end_date) :'',
      student_status: selectedRecord?.is_active === "1" ? setVisibilityValue(true) : setVisibilityValue(false),
    });
  }else{
    form.setFieldsValue({
      survey_name:null,
      actual_course: null,
    });
  }
	if (isAddOrEdit === "edit" && (surveyType === 'Courses' || surveyType === 'Instructor')){
		setShowCourses(true);
		setDisabledSurveyName(true);
		if (surveyType === 'Courses'){
			setCourseOrInstructorType('Courses');
		}else if (surveyType === 'Instructor'){
			setCourseOrInstructorType('Instructor');
		}
		setRefresh(!refresh);
	}
},[surveyType]);

useEffect(()=>{
  if (isAddOrEdit === "edit" && (surveyType === 'Courses' || surveyType === 'Instructor')){
    getCourses(surveyType === 'Courses' ? 'courseType' : surveyType === 'Instructor' ?'instructorType':'');
  }
}, [surveyType,isAddOrEdit])
	const getCourseName =(value,option)=>{
		setSurveyName(option?.children.split('-')[0]);
		setCourseName(option?.children.split('-')[0]);
		form.setFieldsValue({
			survey_name: `${option?.children.split('-')[0]} ${courseOrInstructorType === 'Courses' ? 'Learning Outcomes' : courseOrInstructorType === 'Instructor'?'Instructor':''}`,
		});
		setRefresh(!refresh);
	}
	const disablePastDates = (current) => {
		// Can not select days before today and today
		return current && current < dayjs().startOf('day');
	};
  const getCourses = (type, isEdit)=>{
		setCourseLoading(true);
		let payload = null;
		payload = {
			type: 2,
			value: type === 'courseType' ?  0 : 3,
			discontinue: 0,
			is_hidden: isHiddenSurvey === true ? 1 : 0 ? 1 : 0,
			is_filter:1
		}
		fetchStaffApi(`/get-filtered-courses`, 'POST', payload).then((res) => {
			if(res?.data){
				setCourseLoading(false);
				setAllCourses(res?.data);
				const courseExists = res?.data.some(course => course.id === selectedRecord?.course_id);
				if(!courseExists){
					form.setFieldsValue({actual_course: (surveyType === 'Courses' || surveyType === 'Instructor') ? selectedRecord?.course_name : null})
				}
				if(isEdit==="edit"){
					form.setFieldsValue({actual_course: null})
				}
			}else{
				setCourseLoading(false);
				setAllCourses([]);
			}
			setCourseLoading(false);
		}).catch(() => {
			setCourseLoading(false);
			message.error(constants.ErrorMessage);
		});
	}
  const getCourseType = (value,option) => {
		form.setFieldsValue({
			actual_course: null,
			survey_name:null
		});
		option?.children === 'Courses' || option?.children === 'Instructor' ? setShowCourses(true) : setShowCourses(false);
		option?.children === 'Courses' || option?.children === 'Instructor' ? setDisabledSurveyName(true) : setDisabledSurveyName(false);
		if(option?.children === 'Courses'){
			setCourseOrInstructorType('Courses');
		} else if(option?.children === 'Instructor'){
			setCourseOrInstructorType('Instructor');
		}else{
			setCourseOrInstructorType('Others');
			form.setFieldsValue({
				survey_name: null,
			});
		}
		setRefresh(!refresh);		
		return option?.children === 'Courses' ? getCourses('courseType',"edit") : option?.children === 'Instructor' ? getCourses('instructorType',"edit") : ''
	}
  	//get onChange value of start date field
	const getStartDate = (date, dateString)=>{
		let today = dayjs().format(constants.DateFormat);
		if (dateString === today){
			setVisibilityValue(true);
		}else{
			setVisibilityValue(false);
		}
	}
  const getVisibilityValue = (checked) => {
		setVisibilityValue(checked);
	}
  const validateToDate = (_, value) => {
		const startDate = form.getFieldValue('start_date');
		if (startDate && value && dayjs(value).isBefore(startDate)) {
			const errorPromise = Promise.reject(new Error("Start date must be greater than end date"));
			return errorPromise;
		}
		Promise.resolve().then(() => {

		});
		return Promise.resolve();
	};
  const submitSurveyDetails = (values)=>{
		setLoading(true);
		let payload = null;
		payload = {
			survey_name: courseName?`${surveyName}${courseOrInstructorType === 'Courses' ? 'Learning Outcomes' : courseOrInstructorType === 'Instructor' ? 'Instructor' : ''}` :removeExtraSpaces(values?.survey_name),
			survey_type: values?.survey_type,
			start_date: values?.start_date?values?.start_date.format('YYYY-MM-DD'):null,
			end_date: values?.end_date?values?.end_date.format('YYYY-MM-DD'):null,
			is_active: isAddOrEdit === "add"?0:visibilityValue?1:0,
			admin_id: getCookie(COOKIE.UserId),
		 }
     if(values?.actual_course){
			let convertToInt = parseInt(values?.actual_course);
			if(isNaN(convertToInt)){
				payload.course_id=selectedRecord?.course_id;
			}else{
				payload.course_id=values?.actual_course;
			}
     }
     let url = isAddOrEdit === "add"? 'add-survey' : `edit-survey/${selectedRecord?.id}`;
     let method = isAddOrEdit === "add" ?'POST':'PUT';
		fetchStaffApi(`/${url}`, `${method}`, payload).then((res) => {
			if(res?.code === '200'){
				closeModal(isAddOrEdit === "add" ? setShowAddModal : setShowEditModal);
        clearKeys("success");
				if(isAddOrEdit==="add"){
					setNewAddedRecord(res.data);
				}
				if (buttonClicked === 'save2') {
					setContinueSave(2);
				}
				setLoading(false);
				message.success(res?.message);
			}else if (res?.code === 400 && res?.message === 'Duplicate survey name'){
				form.setFields([
					{
						name: "survey_name",
						errors: [res?.message || "An error occurred"],
					},
				]);
				setLoading(false);
			}
			setLoading(false);
		}).catch(() => {
			setLoading(false);
			message.error(constants.ErrorMessage);
		});
	}
	const handleClick = (buttonType) => {
    setButtonClicked(buttonType);
  };
	return (
		<Modal
		  open={isAddOrEdit==="add"?showAddModal:showEditModal}
		  onCancel={() => { closeModal(isAddOrEdit === "add" ? setShowAddModal : setShowEditModal) }}
		  title={isAddOrEdit==="add"?constants.AddSurvey:`${constants.EditSurvey} - ${selectedRecord?.survey_name}`}
		  className="!top-5"
		  footer={null}
		  width={700}
		>
      <Form 
		    form={form}
				id="myForm"
		    layout={'vertical'} 
		    onFinish={(values)=>submitSurveyDetails(values)}
		  >
			  <Row gutter={[24]}>
				  <Col span={12}>
					  <Form.Item
						  rules={constants.requiredFieldRules}
						  label='Survey Type'
						  name='survey_type'
						  className='!mb-2.5'
					  >
						  <Select
							  placeholder='Survey Type'
							  maxTagCount='responsive'
							  className='w-100'
							  onChange={getCourseType}
							  allowClear
						  >
							  {surveyTypes.map((option, index) => (
							  	<Select.Option key={index} value={option.id}>
							  		{option.content}
							  	</Select.Option>
							  ))}
						  </Select>
					  </Form.Item>
				  </Col>
				  <Col span={12}>
				  	<Form.Item
				  		rules={[{
								required: true, 
								message: 'This field is required',
								whitespace: true,
							}]}
				  		label='Survey Name'
				  		name='survey_name'
				  		className='!mb-2.5'
				  	>
				  		<Input 
								className='w-100' 
								disabled={disabledSurveyName} 
								placeholder='Survey Name' 
								onKeyDown={(e) => checkEmptySpace(e, form.getFieldValue('survey_name'))}
							/>
				  	</Form.Item>
				  </Col>
			  </Row>
			  {
			  	showCourses &&
			  	<Row>
			  		<Col span={24}>
			  			<Form.Item
			  				rules={constants.requiredFieldRules}
			  				label='Course'
			  				name='actual_course'
			  				className='!mb-2.5'
			  			>
			  				<Select placeholder='Course' maxTagCount='responsive' onChange={getCourseName} disabled={courseLoading}>
			  					{allCourses.map((option, index) => (
			  						<Select.Option key={option?.id} value={option?.id}>
			  							{`${option?.course_code} - ${option?.course_name}`}
			  						</Select.Option>
			  					))}
			  				</Select>
			  			</Form.Item>
			  		</Col>
			  	</Row>
			  }
			  <Row gutter={[24]}>
			  	<Col span={12}>
			  		<Form.Item
			  			label='Start Date'
			  			name='start_date'
			  			className='!mb-2.5'
			  		>
			  			<DatePicker className='w-100' format={constants.DateFormat} onChange={getStartDate} disabledDate={disablePastDates} />
			  		</Form.Item>
			  	</Col>
			  	<Col span={12}>
			  		<Form.Item
			  			label='End Date'
			  			name='end_date'
			  			className='!mb-2.5'
			  			rules={[
			  				{ validator: validateToDate },
			  			]}
			  		>
			  			<DatePicker className='w-100' format={constants.DateFormat} disabledDate={disablePastDates} />
			  		</Form.Item>
			  	</Col>
			  </Row>
				{isAddOrEdit==="edit"&&
          <Form.Item
          	label='Status'
            name='student_status'
            className='!mb-2.5'
          >
            <Switch checkedChildren={constants.Active} unCheckedChildren={constants.InActive} style={{ width: '120px' }} onChange={getVisibilityValue} checked={visibilityValue} className=''/>
          </Form.Item>
        }
			  <div className="flex justify-end mt-2">
			  	<Button type="primary" className='mb-3' onClick={() => { closeModal(isAddOrEdit === "add" ? setShowAddModal : setShowEditModal); clearKeys()}} danger>{constants.Cancel}</Button>
			  	{isAddOrEdit==="edit"?
						selectedRecord?.is_active === "1"&&!visibilityValue?<Button onClick={()=>setShowVisibleActive(true)} type="primary" className='ms-2 mb-3'>{constants.Save}</Button>:
						<Button htmlType='submit' disabled={loading} onClick={() => handleClick('save1')} type="primary" className='ms-2 mb-3'>{constants.Save}</Button>
						:<Button htmlType='submit' disabled={loading} onClick={() => handleClick('save1')} type="primary" className='ms-2 mb-3'>{constants.Save}</Button>
					}
			  	{isAddOrEdit==="add"&&<Button disabled={loading} htmlType='submit' className='ms-2 mb-3 bgGreen500' onClick={() => handleClick('save2')}>{constants.SaveAndContinue}</Button>}
			  </div>
				{showVisibleActive&&
					<Modal 
						title={constants.Confirm}
						open={showVisibleActive}
						onCancel={()=>setShowVisibleActive(false)}
						footer={false}
					>
						<>
							<div className='text-sm mb-2 font-bold'>{constants.ActiveSurveyText}</div>
							<div className="mt-3 flex justify-end">
								<Button type='primary' danger onClick={()=>setShowVisibleActive(false)}>Cancel</Button>
								<Button type='primary' disabled={loading} onClick={() => handleClick('save1')} htmlType='submit' form='myForm' className='ms-2'>Save</Button>
							</div>
						</>
					</Modal>}
      </Form>
		</Modal>
	)
}

export default AddAndEditSurveys
